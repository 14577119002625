import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Button } from '@mui/material';
import MDBox from 'components/MDBox';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import MDButton from "components/MDButton";
import { disableCustomerAction } from 'Actions/disableCustomerAction';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function UserTable(props){

    const handleDisable= (x) =>{
        props.disableCustomerAction(x._id);
    }

    return (
        <TableContainer>
        <Table sx={{ minWidth: 650, maxHeight: 40}} stickyHeader >
            <TableHead>
                <TableRow>
                    <TableCell align='left'>S.NO</TableCell>
                    <TableCell align='left'>CustomerName</TableCell>
                    <TableCell align='left'>Cutomer Mail ID</TableCell>
                    <TableCell align="left">Customer PhoneNumber</TableCell>
                    <TableCell align="left">Product Type</TableCell>
                    <TableCell align='left'>status</TableCell>
                    <TableCell align='center'>Edit</TableCell>
                    <TableCell align='center'>Delete</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.customersData && props.customersData.map((x,i)=>{
                    return (
                        <TableRow
                        key={i}>
                            <TableCell>{i+1}</TableCell>
                            <TableCell>{x.customerName}</TableCell>
                            <TableCell>{x.contactEmail}</TableCell>
                            <TableCell>{x.contactPhoneNumber}</TableCell>
                            <TableCell>{x.productType}</TableCell>
                            <TableCell>{x.status}</TableCell>
                            <TableCell>
                                <MDButton>
                                    <EditTwoToneIcon />
                                </MDButton>
                            </TableCell>
                            <TableCell>
                                <Button onClick={handleDisable.bind(this,x)}>
                                    <DeleteSharpIcon/>
                                </Button>
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </TableContainer>
    )
}

const mapStateToProps =(state)=>{
    return {

    }
}

const mapDispatchToProps = (dispatch)=>{
    return {
       disableCustomerAction:(customerId)=>{
            dispatch(disableCustomerAction(customerId))
       }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(UserTable);