import { takeEvery, call, put } from '@redux-saga/core/effects';
import axios from 'axios';
import { updateCustomerApi } from 'consents/apis';
import { updateCutomerData } from 'Actions/updateCustomerAction';
import { X_API_KEY } from 'config';


export function* watchUpdateCustomerData(){
    yield takeEvery('UPDATE_CUSTOMER_DETAILS_ACTION',handleUpdateCustomer)
}

function* handleUpdateCustomer({payload}){
    const Data = yield call(updateCustomerDetails,payload)
    yield put(updateCutomerData(Data));
}

const updateCustomerDetails = async(customerDetails) =>{
    if(customerDetails.billingCountryCode != undefined){
    customerDetails.billingContactPhone = customerDetails.billingCountryCode + customerDetails.billingContactPhone;
    }
    if(customerDetails.countryCode != undefined){
    customerDetails.contactPhoneNumber = customerDetails.countryCode + customerDetails.contactPhoneNumber;
    }
    const params = customerDetails._id
    const updateDetails = {...customerDetails,customerId:customerDetails._id}
    try {
        const CustomerDetails = await axios.put(updateCustomerApi+`/${params}`,updateDetails,{
            headers:{"x-api-key":X_API_KEY}
        })
        return CustomerDetails.data.data;
    }
    catch(err){
         
    }
}