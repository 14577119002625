import react,{useState} from 'react';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { Checkbox, FormControlLabel } from '@mui/material';
import checkbox from "assets/theme/components/form/checkbox";

import './css/index.scss';
import MDTypography from 'components/MDTypography';

function DBCreatePage(props) {
    const [check,setCheck] = useState(false);

     const handleclick = (event,value) =>{
            props.setConfirmStatus(value);
            props.setErrorMessage("");
     }
    
    return (
        <MDBox>
          <div className='create_user'>
          <MDTypography> Customer Conformation</MDTypography>
           <FormControlLabel
           control={
            <Checkbox onChange={handleclick}></Checkbox>
           }
           label='Yes,I confirm customer details and billing details'
            />
            </div>
        </MDBox>
    )
}

export default DBCreatePage;