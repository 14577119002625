import { AppBar, Box, Card, Grid, Tab, Tabs } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import React, { useState } from 'react';
import KalthuraConfig from './kalturaConfig';
import './css/index.scss'

function UsersConfigs(props){
     const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
     
    return (
      <MDBox>
      <MDBox pt={5} pb={3} mx={3}>
          <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} sm={12}>
              <Card>
                 <MDBox mx={2}
                        mt={-3}
                        py={1}
                        px={2}
                        variant="gradient"
                        bgColor="transparent"
                        borderRadius="lg"
                        coloredShadow="info">
                        <Tabs value={value} onChange={handleChange} >
                           <Tab value='one' label="Kaltura"></Tab>
                           <Tab value='two' label='GA' ></Tab>
                           <Tab value ='three' label="GTM"></Tab>
                           <Tab value = 'four' label="AI"></Tab>
                           <Tab value='five' label="Youbora"></Tab>
                        </Tabs>
                  </MDBox>
                    
                  <MDBox mx={2} pb={2}>
                  
                      <Grid container spacing={3}>
                        <Grid item sm={12} xs={12} md={12} lg={12}>
                        {value == 'one' ? <KalthuraConfig data={props}
                                                          setReset={props.setReset}
                                                          configDialog={props.configDialog}
                                                          configOpen={props.configOpen}
                                                          setConfigDialog={props.setConfigDialog}
                                                          setConfigOpen={props.setConfigOpen}
                        />:""}
                        </Grid>
                      </Grid>  
                  </MDBox>
                  </Card>
             </Grid>
          </Grid>
        </MDBox>
      </MDBox>    
    )
}

export default UsersConfigs;