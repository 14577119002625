const table = {
    styleOverrides: {
        root:{

        },
        stickyHeader:{
            stickyHeader:true
        }
    }
}       

export default table;