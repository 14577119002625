const INITAL_STATE = {
    updatedData:{},
    updateDataReset:false
}

export default function updateCustomerReducer(State=INITAL_STATE,action){
    switch (action.type){
        case 'UPDATED_CUSTOMER_DATA':
            return{...State,updatedData:action.payload,updateDataReset:!State.updateDataReset}
        default : 
            return {...State}    
    }
}