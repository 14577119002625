import React from 'react';
import { TextField, Typography, Autocomplete, FormControlLabel, Checkbox } from '@mui/material';
import MDBox from 'components/MDBox';
import './css/index.scss';



function ApplicationConfig(props) {

  const handleCheckboxChange = (event)=>{
    props.setUserDetails({...props.userDetails,  [event.target.name]: event.target.checked,})
 }

  return (
    <MDBox>
      <div className='create_user'>
        <Typography>Application Config</Typography>

    

<div style={{marginLeft:"20px", display:"flex", gap:"10px"}}>
<FormControlLabel
          control={
            <Checkbox
              checked={props.userDetails.pip ? props.userDetails.pip : null}
              onChange={handleCheckboxChange}
              name="pip"
              color="primary"
            />
          }
          label="PIP"
          style={{width:"fit-content"}}
        />
          <FormControlLabel
          control={
            <Checkbox
              checked={props.userDetails.downloadToGo ? props.userDetails.downloadToGo : null}
              onChange={handleCheckboxChange}
              name="downloadToGo"
              color="primary"
            />
          }
          label="DownloadToGo"
          style={{width:"fit-content"}}
        />
        
     </div>
    
      </div>
    </MDBox>
  );
}

export default ApplicationConfig;
