import { takeEvery, call, put } from '@redux-saga/core/effects';
import { getCustomersListData } from 'Actions/getCustomerDataAction';
import axios from 'axios';
import { X_API_KEY } from 'config';
import { getCustomersApi } from 'consents/apis';

export function* watchgetCustomerData(){
    yield takeEvery('GET_CUSTOMER_ACTION',handleGetCustomerData)
}

function* handleGetCustomerData(){
    const Data = yield call(getCustomersDetails)
    yield put(getCustomersListData(Data));
}

const getCustomersDetails = async() =>{
    try {
        const CustomerDetails = await axios.get(getCustomersApi,{
            headers:{"x-api-key":X_API_KEY}
        })
        return CustomerDetails.data.data;
    }
    catch(err){
         
    }
}