import { DialogActions, DialogContent } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { updateConfigAction } from "Actions/updateConfigAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getConfigReset } from 'Actions/getConfigDetails';

const  UpdateConfig = (props)=>{

    const [updateData,setUpdateData] = useState(props.updateData.config)
    const navigate = useNavigate();

    useEffect(()=>{
        setUpdateData({...updateData,t_id:props.Data.tenantId})
    },[])

    const handleApiversion = (event)=>{
        setUpdateData({...updateData,apiVersion:event.target.value})
    }

    const handleApplicationName = (event)=>{
        setUpdateData({...updateData,applicationName:event.target.value})
    }

    const handlePartnerId = (event)=>{
        setUpdateData({...updateData,partnerId:event.target.value})
    }

    const handlePlatform = (event)=>{
        setUpdateData({...updateData,platform:event.target.value})
    }

    const handleClientVersion = (event)=>{
        setUpdateData({...updateData,clientVersion:event.target.value})
    }

    const handleTag = (event)=>{
        setUpdateData({...updateData,tag:event.target.value})
    }

    const handleRootCategoryId = (event)=>{
        setUpdateData({...updateData,rootCategoryId:event.target.value})
    }

    const handleUdid = (event)=>{
        setUpdateData({...updateData,udid:event.target.value})
    }

    const handleBaseUrl = (event)=>{
        setUpdateData({...updateData,baseUrl:event.target.value})
    }

    const handleUsername = (event)=>{
        setUpdateData({...updateData,username:event.target.value})
    }
    const handlePassword = (event)=>{
        setUpdateData({...updateData,password:event.target.value})
    }

    const handleUpdate = ()=>{
       // props.setResetConfig(true);
         props.updateConfigAction(updateData)
         props.getConfigReset();
         props.setConfigOpen(false);
        props.setConfigDialog(false);
        navigate('/customerslist')
        
    } 

    return (
        <MDBox>
        <DialogContent>
          <MDInput 
            label='API Version'
            name='API Version'
            id='API Version'
            size='small'
            fullWidth
            required
            sx={{paddingBottom:'10px',maxWidth:'50%'}}
            onChange={handleApiversion}
            value={updateData.apiVersion ? updateData.apiVersion : ""}
           />
          <MDInput 
              label='Application Name'
              name='Application Name'
              id='Application Name'
              size='small'
              fullWidth
              required
              sx={{paddingBottom:'10px',maxWidth:'50%'}}
              onChange={handleApplicationName}
              value={updateData.applicationName}
             />
          <MDInput
              label='Partner Id'
              name='Partner ID'
              id='Partner ID'
              size='small'
              fullWidth
              required
              sx={{paddingBottom:'10px',maxWidth:'50%'}}
              onChange={handlePartnerId}
              value={updateData.partnerId}
            />
           <MDInput
              label='Platform'
              name='Platfrom'
              id='Platform'
              size='small'
              required
              fullWidth
              sx={{paddingBottom:'10px',maxWidth:'50%'}}
              onChange={handlePlatform}
              value={updateData.platform }
            />
            <MDInput
               label='Client Version'
               name='Client Version'
               id='Client Version'
               size='small'
               required
               fullWidth
               sx={{paddingBottom:'10px',maxWidth:'50%'}}
               onChange={handleClientVersion}
               value={updateData.clientVersion}
             />
            <MDInput
               label='Tag'
               name='Tag'
               id='Tag'
               size='small'
               fullWidth
               required
               sx={{paddingBottom:'10px',maxWidth:'50%'}}
               onChange={handleTag}
               value = {updateData.tag}
            />
            <MDInput 
               label='Root CatgeoryID'
               name='Root CategoryID'
               id='Root CategoryID'
               size='small'
               fullWidth
               required
               sx={{paddingBottom:'10px',maxWidth:'50%'}}
               onChange={handleRootCategoryId}
               value = {updateData.rootCategoryId}
            />
            <MDInput
               label='UDID'
               name='UDID'
               id='UDID'
               size='small'
               fullWidth
               required
               sx={{paddingBottom:'10px',maxWidth:'50%'}}
               onChange={handleUdid}
               value = {updateData.udid}
            />
            <MDInput
              label='Base URL'
              name='Base URL'
              id='Base URL'
              size='small'
              required
              fullWidth
              sx={{paddingBottom:'10px',maxWidth:'50%'}}
              onChange={handleBaseUrl}
              value = {updateData.baseUrl}
            />
            <MDInput 
               label='Username'
               name='Username'
               id='Username'
               size='small'
               fullWidth
               required
               sx={{paddingBottom:'10px',maxWidth:'50%'}}
               onChange={handleUsername}
               value = {updateData.username}
            />
            <MDInput
               label='Password'
               name='Password'
               id='Password'
               size='small'
               fullWidth
               required
               sx={{paddingBottom:'10px',maxWidth:'50%'}}
               onChange={handlePassword}
               value = {updateData.password}
            />
        </DialogContent>
        <DialogActions sx={{paddingBottom:'2px'}}>
                   <MDButton color='info' onClick={handleUpdate}>Save</MDButton>
                   <MDButton color='info' onClick ={props.handleCancel} >Cancel</MDButton>
        </DialogActions>
        </MDBox>
    )
}

const mapStateToProps = (state)=>{
  return {}
}

const mapDispatchToProps = (dispatch)=>{
    return {
        updateConfigAction:(updateDetails)=>{
            dispatch(updateConfigAction(updateDetails))
        },
        getConfigReset:()=>{
            dispatch(getConfigReset())
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateConfig);