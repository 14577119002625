import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import NewStepper from "./Layout/stepper";
import Footer from "examples/Footer";

function UserConsole(){
    return(
        <DashboardLayout>
            <DashboardNavbar />
              <MDBox>
                <Grid container spacing={3}>
                   <Grid item xs={12} md={12} lg={12} sm={12}  >
                      <NewStepper/>
                    </Grid>
                </Grid> 
              </MDBox>  
        </DashboardLayout>
    )
}

export default UserConsole;