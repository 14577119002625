const getMaintenanceScheduleType = "GET_MAINTENANCE_SCHEDULE"
const updateMaintenanceScheduleType = "UPDATE_MAINTENANCE_SCHEDULE"
const getMaintenanceScheduleResonseType = "SUCCESSFULLY_GET_MAINTENANCE_SCHEDULES"
const updateMaintenanceScheduleResponseType = 'SUCCESSFULLY_UPDATE_MAINTENANCE_SCHEDULES'
const createMaintenanceScheduleType = 'CREATE_MAINTENANCE_SCHEDULE'
const createMaintenanceScheduleResponseType = 'SUCCESSFULLY_CREATED_MAINTENANCE_SCHEDULE'

export const getMaintenanceScheduleAction = ()=>{
    return {
        type:getMaintenanceScheduleType
    }
}

export const getMaintenanceScheduleResponseAction = (payload)=>{
    return {
        type:getMaintenanceScheduleResonseType,
        payload
    }
}

export const updateMaintenanceScheduleAction = (payload)=>{
    return {
        type:updateMaintenanceScheduleType,
        payload
    }
}

export const updateMaintenanceScheduleResponseAction = (payload)=>{
    return {
        type:updateMaintenanceScheduleResponseType,
        payload
    }
}

export const createMaintenanceScheduleAction = (payload)=>{
    return {
        type:createMaintenanceScheduleType,
        payload
    }
}

export const createMaintenanceScheduleResponseAction = (payload)=>{
    return {
        type:createMaintenanceScheduleResponseType,
        payload
    }
}
