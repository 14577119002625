const InitialState = {
    configData:{}
}

export default function createConfigReducer (state = InitialState,action){
    switch(action.type){
        case 'CREATE_NEW_CONFIG':{
            return {...state,configData:action.payload}
        }
        default :
          return {...state}
    }
}