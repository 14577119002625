import React,{useEffect, useState} from 'react';
import { Stepper, Step,StepLabel,Box, Typography,Button, Alert, CircularProgress, Skeleton, Grid, Card } from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';

import Step1 from './userdetails';
import BillingDetails from './BillingDetails';
import DBCreatePage from './DBcerate';
import UsersInformation from './userInformation';
import FeatureConfig from './FeatureConfig'; // Import FeatureConfig

import { Navigate, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createUserDetailsAction } from 'Actions/createUserAction';
import { createDatabaseAction } from 'Actions/createDatabaseAction';
import ApplicationConfig from './ApplicationConfig';
import ThirdPartyConfig from './3rdPartyConfig';

const step = [
  "User Details", "Feature Config","Application Config","Thirdparty Config","Billing Details", "Confirmation", "Customer Details"
];

function NewStepper (props){
    const [activeStep,setactiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [skipped, setSkipped] = useState(new Set());
  const [userDetails,setUserDetails] = useState({})
  const [featureConfig,setFeatureConfig] = useState({})
  console.log("🚀 ~ NewStepper ~ userDetails:", userDetails)
  const [confirmStatus,setConfirmStatus] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");
  const [mailError,setMailError] = useState(true)
  const [load,setLoad] = useState(false);
  const navigate = useNavigate();
  
  useEffect(()=>{
    if(load == true){
      setTimeout(()=>{
        setLoad(false)
      },1000)
    }
  },[load])

  /*useEffect(()=>{
    console.log('active',)
     const number = userDetails.countryCode+userDetails.PhoneNumber
        setUserDetails({...userDetails,contactPhoneNumber:number})
  },[load])

  useEffect(()=>{
    const bnumber = userDetails.countryCode+userDetails.bContactPhone
    setUserDetails({...userDetails,billingContactPhone:bnumber})
  },[load])*/


  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleNext = () => {
    
    if(activeStep=== 5 && confirmStatus === false){
      console.log(userDetails);
      setErrorMessage('please confirm user details and billing details')
    }
    else{
      
      if(activeStep === 0){
        let mail = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
           let mailValid = mail.test(userDetails.contactEmail);
           if(mailValid === false){
             setMailError(false)
             setErrorMessage("InvalidEmail address")
           }
           else{
             setMailError(true)
           }
        if(userDetails.customerName === undefined || userDetails.customerName === ""){
          setErrorMessage(" mandatory field *")
        }
        else if(userDetails.legalEntityName === undefined || userDetails.legalEntityName ===""){
           setErrorMessage("  mandatory field *")
        }
        else if(userDetails.legalEntityAddress === undefined || userDetails.legalEntityAddress ===""){
           setErrorMessage("  mandatory field *")
        }
        else if(userDetails.country === undefined || userDetails.country === null){
           setErrorMessage("  mandatory field *")
        }
        else if(userDetails.pincode === undefined || userDetails.pincode ===""){
           setErrorMessage("  mandatory field *")
        }
        else if(userDetails.contactName === undefined || userDetails.contactName ===""){
           setErrorMessage("  mandatory field *")
        }
        else if(userDetails.contactEmail === undefined || userDetails.contactEmail ==="" || mailValid === false){
           setErrorMessage("  mandatory field *  or enter valid email address")
           
        }
        else if(userDetails.contactPhoneNumber === undefined || userDetails.contactPhoneNumber ===""){
           setErrorMessage("  mandatory field *")
        }
        else{
          setErrorMessage("");
          setactiveStep((prevActiveStep) => prevActiveStep + 1);
          setLoad(true);
        }
      } else if (activeStep === 1) {
        setErrorMessage("");
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoad(true);
      } else if (activeStep === 2) {
        setErrorMessage("");
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoad(true);
      }
      else if (activeStep === 3) {
        setErrorMessage("");
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoad(true);
      }
       else if (activeStep === 4) {
        if(userDetails.productType === undefined || userDetails.productType === null){
          setErrorMessage("  mandatory field *")
        }
        else if(userDetails.billingContactName === undefined || userDetails.billingContactName ===""){
           setErrorMessage("  mandatory field *")
        }
        else if(userDetails.billingContactEmail === undefined || userDetails.billingContactEmail ===""){
          setErrorMessage("  mandatory field *")
       }
       else if(userDetails.billingContactCountry === undefined || userDetails.billingContactCountry === null){
        setErrorMessage(" mandatory field *")
       }
       else if(userDetails.billingContactAddress === undefined || userDetails.billingContactAddress ===""){
         setErrorMessage(" mandatory field *")
        }
        else if(userDetails.billingPincode === undefined || userDetails.billingPincode ===""){
          setErrorMessage(" mandatory field *")
       }
       else if(userDetails.billingContactPhone === undefined || userDetails.billingContactPhone ===""){
        setErrorMessage("mandatory field *")
       }
       else if(userDetails.billingDuration === undefined || userDetails.billingDuration === null){
        setErrorMessage(" mandatory field *")
     }
        else{
          setErrorMessage("");
   
          setactiveStep((prevActiveStep) => prevActiveStep + 1);
          setLoad(true);
        }
      } else if (activeStep === 5) {
        props.createUserDetailsAction({userDetails,featureConfig});
        setErrorMessage("");
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoad(true);
      } else if (activeStep === 6) {
        setErrorMessage("");
        setactiveStep((prevActiveStep) => prevActiveStep + 1);
        setLoad(true);
      }
    }
  };

  const handleReset = () => {
    //setactiveStep(0);
    setConfirmStatus(false)
    navigate('/customerslist')
  };

  /*const isStepOptional = (step) => {
    return step === 1;
  };*/

  /*const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setactiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };*/

  const handleBack = () => {
    setactiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 3 && confirmStatus === true) {
      setConfirmStatus(false);
    }
  };

    return (
      <MDBox pt={6} pb={3}>
                <Grid container spacing={2}>
                   <Grid item xs={12} md={12} lg={12} sm={12}>
                <Card>
              <MDBox
                mx={2}
                mt={-6}
                py={3}
                px={1}
              >
               <Stepper  alternativeLabel nonLinear activeStep={activeStep}>
              {step.map((label,index)=>{
                return (
                  <Step key={label} >
                  <StepLabel >
                    {label}
                  </StepLabel>
                  </Step>
                )
              })}
              </Stepper> 
              </MDBox>
              <MDBox>
        {load ? <MDBox px={3}>
             <CircularProgress/>
          </MDBox> : ""}

              {activeStep === 0 ? (
                <Step1 userDetails={userDetails} setUserDetails={setUserDetails} errorMessage={errorMessage} mailError={mailError} />
              ) : null}
              {activeStep === 1 && load === false ? (
                <FeatureConfig userDetails={featureConfig} setUserDetails={setFeatureConfig} />
              ) : null}
               {activeStep === 2 && load === false ? (
                <ApplicationConfig userDetails={featureConfig} setUserDetails={setFeatureConfig} />
              ) : null}
               {activeStep === 3 && load === false ? (
                <ThirdPartyConfig userDetails={featureConfig} setUserDetails={setFeatureConfig} />
              ) : null}
              {activeStep === 4 && load === false ? (
                <BillingDetails userDetails={userDetails} setUserDetails={setUserDetails} errorMessage={errorMessage} />
              ) : null}
              {activeStep === 5 && load === false ? (
                <DBCreatePage confirmStatus={confirmStatus} setConfirmStatus={setConfirmStatus} setErrorMessage={setErrorMessage} />
              ) : null}
              {activeStep === 6 && load === false ? <UsersInformation /> : null}
              {activeStep === 3 && !props.usersData ? (
                <MDBox py={1}>
                  <Alert severity="error" variant="outlined" sx={{ maxWidth: "50%" }}></Alert>
                </MDBox>
              ) : (
                ""
              )}

              {activeStep === 5 && errorMessage ? (
                <MDBox py={1} className="create_user">
                  <Alert severity="error" variant="outlined" sx={{ maxWidth: "50%" }}>
                    {errorMessage}
                  </Alert>
                </MDBox>
              ) : (
                ""
              )}
              <MDBox sx={{ display: 'flex', flexDirection: 'row' }} px={4} py={3}>
                 {
                activeStep !== 0 &&
                activeStep !== 6  ? (
                  <MDButton
                  color="info"
                  sx={{ mr: 1 }}
                  onClick={handleBack}
                 >
                 Back
               </MDButton>
                ) : (
                  ""
                )}
                 
                <MDBox sx={{ flex: 'auto' }} />
                <MDButton onClick={activeStep === step.length - 1 ? handleReset : handleNext} color="info">
                  {activeStep === step.length - 1 ? 'OK' : 'Next'}
                </MDButton>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
    /*<MDBox px={1} my={-2}> 
        
                  </MDBox> */
          
    )
}

const mapStateToProps=(state)=>{
  return {
       usersData:state.CreateUserDetailsReducer.usersData,
       //customerInfo:state.getCustomerInfoReducer.customerInfo
  }
}

const mapDispatchToProps=(dispatch)=>{
  return {
    createUserDetailsAction:(usersDetails)=>{
      dispatch(createUserDetailsAction(usersDetails))
    },
    createDatabaseAction:(customerDetails)=>{
      dispatch(createDatabaseAction(customerDetails))
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(NewStepper);