import { Box, DialogActions, DialogContent, DialogTitle,Grid,TextField,Typography } from '@mui/material';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import React, { useEffect, useState } from 'react';
import { createConfigAction } from 'Actions/createConfigAction';
import { connect } from 'react-redux';
import { getConfigAction } from 'Actions/getConfigDetails';
import UpdateConfig from './updateKalturaconfig';

function KalthuraConfig(props){
    const [configDetails,setConfigDetails] = useState({})
    const [resetConfig,setResetConfig] = useState(false)
    const [errorMessage,setErrorMessage] = useState("");

    useEffect(()=>{
        props.getConfigAction(props.data.Data.tenantId)
       setConfigDetails({...configDetails,t_id:props.data.Data.tenantId})
    },[resetConfig])
     
    const handleApiversion = (event)=>{
         setConfigDetails({...configDetails,apiVersion:event.target.value})
    }

    const handleApplicationName = (event)=>{
        setConfigDetails({...configDetails,applicationName:event.target.value})
    }

    const handlePartnerId = (event,value)=>{
        setConfigDetails({...configDetails,partnerId:event.target.value})
    }

    const handlePlatform = (event)=>{
        setConfigDetails({...configDetails,platform:event.target.value})
    }

    const handleClientVersion = (event)=>{
        setConfigDetails({...configDetails,clientVersion:event.target.value})
    }

    const handleTag = (event)=>{
        setConfigDetails({...configDetails,tag:event.target.value})
    }

    const handleRootCategoryId = (event)=>{
        setConfigDetails({...configDetails,rootCategoryId:event.target.value})
    }

    const handleBaseUrl = (event)=>{
        setConfigDetails({...configDetails,baseUrl:event.target.value})
    }

    const handleUsername = (event)=>{
        setConfigDetails({...configDetails,username:event.target.value})
    }

    const handlePassword = (event)=>{
        setConfigDetails({...configDetails,password:event.target.value})
    }

    const handleUdid = (event)=>{
        setConfigDetails({...configDetails,udid:event.target.value})
    }

    const handleConfigSave = ()=>{
        if(configDetails.apiVersion === undefined || configDetails.apiVersion === ""){
            setErrorMessage("  mandatory field *")
          }
          else if(configDetails.applicationName === undefined || configDetails.applicationName ===""){
             setErrorMessage("  mandatory field *")
          }
          else if(configDetails.partnerId === undefined || configDetails.partnerId ===""){
            setErrorMessage("  mandatory field *")
         }
         else if(configDetails.platform === undefined || configDetails.platform ===""){
            setErrorMessage("  mandatory field *")
         }
         else if(configDetails.clientVersion === undefined || configDetails.clientVersion ===""){
            setErrorMessage("  mandatory field *")
         }
         else if(configDetails.tag === undefined || configDetails.tag ===""){
            setErrorMessage("  mandatory field *")
         }
         else if(configDetails.rootCategoryId === undefined || configDetails.rootCategoryId ===""){
            setErrorMessage("  mandatory field *")
         }
         else if(configDetails.baseUrl === undefined || configDetails.baseUrl ===""){
            setErrorMessage("  mandatory field *")
         }
         else if(configDetails.username === undefined || configDetails.username ===""){
            setErrorMessage("  mandatory field *")
         }
         else if(configDetails.password === undefined || configDetails.password ===""){
            setErrorMessage("  mandatory field *")
         }
         else if(configDetails.udid === undefined || configDetails.udid ===""){
            setErrorMessage("  mandatory field *")
         }
          else{
        props.createConfigAction(configDetails);
        props.data.setConfigDialog(false);
          }
    }

    const handleCancel = ()=>{
        props.data.setConfigOpen(false);
        props.data.setConfigDialog(false);
    }

    return (
        <MDBox>
        {!props.configData.config ? 
        <MDBox>
          <DialogContent>
                <MDInput 
                   label='API Version'
                   name='API Version'
                   id='API Version'
                   size='small'
                   fullWidth
                   required
                   sx={{paddingBottom:'10px',maxWidth:'50%'}}
                   onChange={handleApiversion}
                   error={configDetails.apiVersions ===""}
                   helperText={configDetails.apiVersion === "" || configDetails.apiVersion === undefined ? errorMessage : ""}
                   />
                <MDInput 
                    label='Application Name'
                    name='Application Name'
                    id='Application Name'
                    size='small'
                    fullWidth
                    required
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handleApplicationName}
                    error={configDetails.applicationName ===""}
                   helperText={configDetails.applicationName === "" || configDetails.applicationName === undefined ? errorMessage : ""}
                />
                <MDInput
                    label='Partner Id'
                    name='Partner ID'
                    id='Partner ID'
                    size='small'
                    fullWidth
                    required
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handlePartnerId}
                    error={configDetails.partnerId ===""}
                   helperText={configDetails.partnerId === "" || configDetails.partnerId === undefined ? errorMessage : ""}
                />
                <MDInput
                    label='Platform'
                    name='Platfrom'
                    id='Platform'
                    size='small'
                    required
                    fullWidth
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handlePlatform}
                    error={configDetails.platform ===""}
                   helperText={configDetails.platform === "" || configDetails.platform === undefined ? errorMessage : ""}
                />
                <MDInput
                    label='Client Version'
                    name='Client Version'
                    id='Client Version'
                    size='small'
                    required
                    fullWidth
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handleClientVersion}
                    error={configDetails.clientVersion ===""}
                   helperText={configDetails.clientVersion === "" || configDetails.clientVersion === undefined ? errorMessage : ""}
                />
                <MDInput
                    label='Tag'
                    name='Tag'
                    id='Tag'
                    size='small'
                    fullWidth
                    required
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handleTag}
                    error={configDetails.tag ===""}
                   helperText={configDetails.tag === "" || configDetails.tag === undefined ? errorMessage : ""}
                />
                <MDInput 
                    label='Root CatgeoryID'
                    name='Root CategoryID'
                    id='Root CategoryID'
                    size='small'
                    fullWidth
                    required
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handleRootCategoryId}
                    error={configDetails.rootCategoryId ===""}
                   helperText={configDetails.rootCategoryId === "" || configDetails.rootCategoryId === undefined ? errorMessage : ""}
                />
                <MDInput
                    label='UDID'
                    name='UDID'
                    id='UDID'
                    size='small'
                    fullWidth
                    required
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handleUdid}
                    error={configDetails.udid ===""}
                   helperText={configDetails.udid === "" || configDetails.udid === undefined ? errorMessage : ""}
                />
                <MDInput
                    label='Base URL'
                    name='Base URL'
                    id='Base URL'
                    size='small'
                    required
                    fullWidth
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handleBaseUrl}
                    error={configDetails.apiVersions ===""}
                   helperText={configDetails.baseUrl === "" || configDetails.baseUrl === undefined ? errorMessage : ""}
                />
                <MDInput 
                    label='Kaltura Username'
                    name='Username'
                    id='Username'
                    size='small'
                    fullWidth
                    required
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handleUsername}
                    error={configDetails.username ===""}
                   helperText={configDetails.username === "" || configDetails.username === undefined ? errorMessage : ""}
                />
                <MDInput
                    label='Kaltura Password'
                    name='Password'
                    id='Password'
                    size='small'
                    fullWidth
                    required
                    sx={{paddingBottom:'10px',maxWidth:'50%'}}
                    onChange={handlePassword}
                    error={configDetails.password ===""}
                   helperText={configDetails.password === "" || configDetails.password === undefined ? errorMessage : ""}
                />
               </DialogContent>
               <DialogActions sx={{paddingBottom:'2px'}}>
                   <MDButton color='info' onClick={handleConfigSave}>Save</MDButton>
                   <MDButton color='info' onClick={handleCancel}>Cancel</MDButton>
               </DialogActions>
               </MDBox>
               : 
               <UpdateConfig updateData = {props.configData}
                             handleCancel = {handleCancel}
                             Data = {props.data.Data}
                             setResetConfig = {setResetConfig}
                             setConfigDialog={props.setConfigDialog}
                             setConfigOpen={props.setConfigOpen}
               />}   
        </MDBox>
    )
}

const mapStateToProps = (state)=>{
    return {
        configData:state.getConfigReducer.configData
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        createConfigAction:(configDetails)=>{
            dispatch(createConfigAction(configDetails))
        },
        getConfigAction:(configPayload)=>{
            dispatch(getConfigAction(configPayload))
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(KalthuraConfig);