const InitialState= {
    configData:{}
}

export default function getConfigReducer(state = InitialState,action){
     switch(action.type){
         case 'SUCCESSFULLY_GET_CONFIG':
             return {...state,configData:action.payload}
         case 'CONFIG_RESET':
             return{...state,configData:{}}    
         default :
         return {...state}     
     }
}