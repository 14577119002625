const INITAL_STATE = {
    customerInfo:[]
}

export default function getCustomerInfoReducer(state=INITAL_STATE,action){
    switch(action.type){
        case 'CUSTOMET_INFO_DETAILS' : 
            return {...state,customerInfo:action.payload}
        
        default :
            return {...state}
    }
}