import { takeEvery, call, put,} from '@redux-saga/core/effects'
import { updateConfigData } from 'Actions/updateConfigAction';
import axios from 'axios';
import { X_API_KEY } from 'config';
import { updateConfigApi } from 'consents/apis';


export function* watchupdateConfigSaga(){
    yield takeEvery('UPDATE_CONFIG_DETAILS',handleUpdateConfig)
}

function* handleUpdateConfig({payload}){
    const UpdateConfig = yield call(updateConfigSaga,payload)
    yield put(updateConfigData(UpdateConfig))
}

const updateConfigSaga = async (updateDetails)=>{
    try{
        const res = await axios.put(updateConfigApi,updateDetails,{
                headers:{"x-api-key":X_API_KEY}
        })
        return res.data
    }
    catch(err){

    }
}