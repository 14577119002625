import { all } from 'redux-saga/effects';
import { watchCreateUser } from './createUserSaga';
import { watchgetCustomerData } from './getCustomerDataSaga';
import { watchDisableCustomer } from './disableCustomerSaga';
import { watchCreateDatabase } from './createDatabaseSaga';
import { watchGetCustomerInfo } from './getCustomerInfoSaga';
import { watchUpdateCustomerData } from './updateCustomerSaga';
import { watchCreateConfig } from './createCongifSaga';
import { watchgetConfigDetails } from './getConfigSaga';
import { watchupdateConfigSaga } from './updateConfigSaga';
import { watchgetMaintenanceSchedule } from './maintenanceScheduleSaga';

export default function* rootSaga() {
    yield all([
       watchCreateUser(),
       watchgetCustomerData(),
       watchDisableCustomer(),
       watchCreateDatabase(),
       watchGetCustomerInfo(),
       watchUpdateCustomerData(),
       watchCreateConfig(),
       watchgetConfigDetails(),
       watchupdateConfigSaga(),
       watchgetMaintenanceSchedule()
    ]);
}