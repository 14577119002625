const list = {
  styleOverrides: {
    padding: {
      paddingTop: 20,
      paddingBottom: 10,
    },
  },
};

export default list;
