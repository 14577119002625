import {ConsoleAPI, ConsoleAPI_DEV, Create_Default_User_Api, Create_FeatureConfig_Api} from '../config';
import { Console_User_Api } from '../config';

// const ConsoleAPI = process.env.ConsoleAPI 
// const Create_FeatureConfig_Api = process.env.Create_FeatureConfig_Api
// const Console_User_Api = process.env.Console_User_Api
// const Create_Default_User_Api = process.env.Create_Default_User_Api

export const CreateCustomerApi = ConsoleAPI+'/createCustomer';
export const getCustomersApi = ConsoleAPI+'/getCustomers';
export const disableCustomerApi = ConsoleAPI+'/changeCustomerStatus';
export const createDatabaseApi =  ConsoleAPI+'/createDb';
export const createFeatureConfigApi =  Create_FeatureConfig_Api+'/featureConfig';
export const createUserApi = Console_User_Api+'/createUser';
export const createDefaultUserApi = Create_Default_User_Api+'/register-user';
export const getCustomerInfoApi = ConsoleAPI+'/getCustomer';
export const updateCustomerApi = ConsoleAPI+'/updateCustomer';
export const createConfigApi = ConsoleAPI+'/createKalturaConfig';
export const getConfigApi = ConsoleAPI+'/getKalturaConfig';
export const updateConfigApi = ConsoleAPI+'/updateKalturaConfig';
export const maintenanceApi = ConsoleAPI_DEV + '/maintenance'