const INITAL_STATE = {
    disableMessage:{},
    customerListReset:false
}

export default function disableCustomerReducer(state = INITAL_STATE,action){
    switch(action.type){
        case "DISABLE_CUSTOMER_DATA" :{
            return {...state,disableMessage:action.payload,customerListReset:!state.customerListReset}
        }
        default :{
            return{...state}
        }
    }

}