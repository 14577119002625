import { takeEvery,call,put } from "redux-saga/effects";
import axios from 'axios';
import { getCustomerInfoApi } from "consents/apis";
import { getCustomerInfoData } from "Actions/getCustomerInfoAction";
import { X_API_KEY } from "config";

export function* watchGetCustomerInfo(){
    yield takeEvery('GET_CUSTOMER_INFO_ACTION',handleGetCustomerInfo)
}

function* handleGetCustomerInfo({payload}){
    const info = yield call(getCustomerInfo,payload);
    yield put(getCustomerInfoData(info));
}

const getCustomerInfo = async(customerId)=>{
   try{
       const res = await axios.get(getCustomerInfoApi,{params:customerId,
        headers:{"x-api-key":X_API_KEY}
    });
       return res.data.data
   }
   catch(err){

   }
}