// Material Dashboard 2 React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth } = borders;
const { light } = colors;

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(10)} ${pxToRem(14)}`,
      //borderBottom: `${borderWidth[0]} solid ${light.main}`,
    },
  },
};

export default tableCell;
