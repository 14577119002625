import React from 'react';
import { TextField } from '@mui/material';

const DateTimeComponent = ({label, selectedDate, setSelectedDate}) => {

  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); 
  

  const firstDayOfMonth = `${year}-${month}-${now.getDate()-1}T00:00`;
  const lastDayOfMonth = new Date(year, now.getMonth() + 1, 0); 
  const formattedLastDay = `${year}-${month}-${String(lastDayOfMonth.getDate()).padStart(2, '0')}T23:59`;


  const handleDateChange = (event) => {
    console.log(event.target.value,"event.target.value")
    setSelectedDate(new Date(event.target.value).toISOString());
  };

  return (
    <TextField
      label={label}
      type="datetime-local"
      value={selectedDate}
      onChange={handleDateChange}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        min: firstDayOfMonth,
        max: formattedLastDay,
      }}
      fullWidth
    />
  );
};

export default DateTimeComponent;
