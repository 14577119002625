import { takeEvery, call, put } from '@redux-saga/core/effects';
import axios from 'axios';
import {CreateCustomerApi, createFeatureConfigApi} from '../consents/apis'
import { createUserDetailsData } from 'Actions/createUserAction';
import { X_API_KEY } from 'config';
import { USER_X_API_KEY } from 'config';
import { createDatabaseApi } from 'consents/apis';
import { createUserApi  } from 'consents/apis';
import { createDatabaseData } from 'Actions/createDatabaseAction';
import { createDefaultUserApi } from '../consents/apis';
import { Create_User_X_API_KEY } from 'config';
import { CreateDefaultUserData } from 'Actions/createDatabaseAction';



export function* watchCreateUser(){
    yield takeEvery('CREATE_NEW_USER_ACTION',handleCreateUser);
}

function* handleCreateUser({payload}){
    //create CustomerDetails call
    const Data = yield call(createUserData,payload.userDetails);
    yield put(createUserDetailsData(Data));

    const customerId = { customerId:Data}

    //createDatabse and DBUser Creation
    const message = yield call(createNewDatabase,customerId);
    yield put(createDatabaseData(message));

    const userDetails = {
        t_id:message.tenantId
    }
    const featureConfig = payload.featureConfig
    const featureConfigDetails = {
        "t_id":userDetails.t_id,
        "sara": featureConfig.sara,
        "saraInsights": featureConfig.saraInsights,
        "watched": featureConfig.watched,
        "byw_watched": featureConfig.byw_watched,
        "watch_live": (featureConfig.watch_live || 0)*60,
        "gamification":featureConfig.gamification || false,
        "aiCompanion":featureConfig.aiCompanion || false,
        "personalization":featureConfig.personalization || false,
        "appFeature":featureConfig.appFeature,
        "sia":featureConfig.sia || false,
        "kalturaRE":featureConfig.kalturaRE || false,
        "player":featureConfig.player ,
        "paymentGateway":featureConfig.paymentGateway || {},
        }
    //default User Creation
    const userData = yield call(userCreation,userDetails,featureConfigDetails)
    yield put(CreateDefaultUserData(userData))

}


//create Customer Details API
const createUserData = async (usersDetails) =>{
    usersDetails.billingContactPhone = usersDetails.billingCountryCode + usersDetails.billingContactPhone;
    usersDetails.contactPhoneNumber = usersDetails.countryCode + usersDetails.contactPhoneNumber;

    try{
        const res = await axios.post(CreateCustomerApi,usersDetails,{
            headers:{"x-api-key":X_API_KEY}
        });
        const customerId = res.data.data.insertedId
        return customerId;
    }

    catch(err){
        return err.response.data
    }
}

//create Database and Database User API
const createNewDatabase = async (customerDetails) =>{
    try{
        const res = await axios.post(createDatabaseApi,customerDetails,{
            headers:{"x-api-key":X_API_KEY}
        });
        const userDetails = {
            customerId:customerDetails.customerId,
            tenantId:res.data.tenantId
        }
        const user = await axios.post(createUserApi,userDetails,
            {
                headers:{"x-api-key":USER_X_API_KEY}
            })
            const response = {
                tenantId:res.data.tenantId,
                userCreateMessage:user.data
            }
        return response
        }
    catch(err){
        return err.response.data
    }
}

// create Default User API
const userCreation = async (userDetails,featureConfigDetails)=>{
     try {
         const  res = await axios.post(createDefaultUserApi,userDetails,{
             headers:{"x-api-key":Create_User_X_API_KEY}
         })
         await new Promise(res=>{
            setTimeout(async()=>{
                const featureConfigResponse = await axios.post(createFeatureConfigApi,featureConfigDetails,{
                    headers:{"x-api-key":X_API_KEY}
                });
                return res()
            },10000)
         })
         return res.data
     }
     catch(err){
         return err.responses.data
     }
}
