const INTIAL_STORE={
    usersData:{}
    }
    
    export default function CreateUserDetailsReducer(state=INTIAL_STORE,action){
        switch(action.type){
            case 'CREATE_NEW_USER_SUCCESSFULLY':
            return{...state,usersData:{customerId:action.payload}}
            default : 
            return{...state}
        }
    }