const IntialState = {
    updatedData:{}
}

export default function updateConfigReducer(state=IntialState,action){
    switch(action.type){
        case 'SUCCESSFULLY_UPDATE_CONFIG':
            return{...state,updateData:action.payload}
        default :
            return{...state}    
    }

}