import { Autocomplete, Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import React, { useState,useEffect } from 'react'
import { countiresList } from 'data/countries';

import './css/index.scss';

const allCountriesData = [
  "IND","US","UAE"
];

function Step1(props){
    //const [userDetails,setUserDetails] =  useState({})
    const [countryCode,setCountryCode] = useState("");

    useEffect(() => {
      props.setUserDetails({...props.userDetails,countryCode:countryCode || props.userDetails.countryCode})
    }, [countryCode])

    const handlecustomername = (event) =>{
      props.setUserDetails({...props.userDetails,customerName:event.target.value})
    }

    const handleLEName = (event) =>{
      props.setUserDetails({...props.userDetails,legalEntityName:event.target.value})
    }

    const handleLEEmail = (event) =>{
      props.setUserDetails({...props.userDetails,legalEntityAddress:event.target.value})
    }

    const handleCountries = (event,value) =>{
      countiresList.map((x)=>{
        if(x.name == value){
          setCountryCode(x.dialCode)
          props.setUserDetails({...props.userDetails,countryCode:x.dialCode})
        }
      })
      props.setUserDetails({...props.userDetails , country:value})
    }

    const handlePincode = (event) =>{
      props.setUserDetails({...props.userDetails,pincode:event.target.value})
    }

    const handleContactName = (event) => {
      props.setUserDetails({...props.userDetails,contactName:event.target.value})
      
    }

    const handleContactEmail = (event) =>{
      props.setUserDetails({...props.userDetails,contactEmail:event.target.value})
    }

    const handlePhoneNumber = (event) =>{
      //let obj = `${countryCode}${event.target.value}`
      props.setUserDetails({...props.userDetails,contactPhoneNumber:event.target.value})
    }

    return (
      <MDBox >
        <div className='create_user'>
          <MDTypography>Customer Creation</MDTypography>
            <MDInput
          margin='normal'
          required
          id='Customer Name'
          size='small'
          label='Customer Name'
          name='customer Name'
          value={props.userDetails.customerName ? props.userDetails.customerName:""}
          onChange={handlecustomername}
          sx={{maxWidth:"50%",paddingBottom:"0px"}}
          placeholder="only alphabetic charcters are allowed"
          error={props.userDetails.customerName ===""}
          helperText={props.userDetails.customerName === "" || props.userDetails.customerName === undefined ? props.errorMessage : ""}
        />

        <MDInput
          margin='normal'
          required
          id='Legal Entity Name'
          size='small'
          label='Legal Entity Name'
          name='Legal Entity Name'
          onChange={handleLEName}
          value={props.userDetails.legalEntityName ? props.userDetails.legalEntityName : ""}
          sx={{maxWidth:"50%",paddingBottom:"0.5px"}}
          placeholder="only alphabetic charcters are allowed"
          error={props.userDetails.legalEntityName === ""}
          helperText={props.userDetails.legalEntityName === "" || props.userDetails.legalEntityName === undefined ? props.errorMessage : ""}
        />

        <MDInput
          margin='normal'
          required
          id='Legal Entity Address'
          size='small'
          label='Legal Entity Address'
          name='Legal Entity Address'
          sx={{paddingBottom:"10px", maxWidth:'50%'}}
          onChange={handleLEEmail}
          value={props.userDetails.legalEntityAddress ? props.userDetails.legalEntityAddress : ""}
          placeholder=""
          error={props.userDetails.legalEntityAddress ===""}
          helperText={props.userDetails.legalEntityAddress === "" || props.userDetails.legalEntityAddress === undefined ? props.errorMessage : ""}
        />
        <Autocomplete 
          disablePortal
          size='small'
          id='combo-box-demo'
          options={countiresList.map((countires) => {
            return countires.name;
          })}
          renderInput={(params) => <TextField {...params} label='Select Country' 
          error={props.userDetails.country === ""}
          helperText={props.userDetails.country === "" || props.userDetails.country === undefined ? props.errorMessage : ""}
          />}
          onChange={handleCountries}
          sx={{paddingBottom:"px",maxWidth:"50%"}}
          value={props.userDetails.country ? props.userDetails.country : null}
          
        />
        <MDInput
          margin='normal'
          required
          id='Pincode'
          size='small'
          label='Pincode'
          name='Pincode'
          sx={{maxWidth:"50%",paddingBottom:"0px"}}
          onChange={handlePincode}
          value={props.userDetails.pincode ? props.userDetails.pincode : ""}
          placeholder="ex:1234"
          error={props.userDetails.pincode ===""}
          helperText={props.userDetails.pincode === "" || props.userDetails.pincode === undefined ? props.errorMessage : ""}
        />

        <MDInput
          margin='normal'
          required
          id='Contact Name'
          size='small'
          label='Contact Name'
          name='Contact Name'
          sx={{maxWidth:"50%",paddingBottom:"1px"}}
          onChange={handleContactName}
          value={props.userDetails.contactName ? props.userDetails.contactName:""}
          placeholder="only alphabetic charcters are allowed"
          error={props.userDetails.contactName ===""}
          helperText={props.userDetails.contactName === "" || props.userDetails.contactName === undefined ? props.errorMessage : ""}
        />

        <MDInput
          margin='normal'
          required
          id='Contact Email'
          size='small'
          label='Contact Email'
          name='Contact Email'
          sx={{maxWidth:"50%",paddingBottom:"1px"}}
          onChange={handleContactEmail}
          value={props.userDetails.contactEmail ? props.userDetails.contactEmail:""}
          placeholder="ex:test@gmail.com"
          error={props.userDetails.contactEmail === "" || props.mailError === false}
          helperText={props.userDetails.contactEmail === "" || props.userDetails.contactEmail === undefined  || props.mailError === false
          ? props.errorMessage : ""}
        />

        <MDInput
          margin='normal'
          required
          id='ticket_name'
          size='small'
          label='Contact PhoneNumber'
          name='Contact PhoneNumber'
          sx={{maxWidth:"50%",paddingBottom:"1px"}}
          onChange={handlePhoneNumber}
          value={props.userDetails.contactPhoneNumber ? props.userDetails.contactPhoneNumber : ""}
          placeholder="ex:9876543210"
          InputProps={{
            startAdornment: <InputAdornment  position="start">
              <Typography style={{fontSize:'14px'}}>{props.userDetails.countryCode}</Typography>
            </InputAdornment>,
          }}
          error={props.userDetails.contactPhoneNumber ===""}
          helperText={props.userDetails.contactPhoneNumber === "" || props.userDetails.contactPhoneNumber === undefined ? props.errorMessage : ""}
        />
        </div>
        </MDBox>
    )
}

export default Step1;