const INTIAL_STATE={
    customersData:[]
   }

export default function getCustomersDataReducer (state = INTIAL_STATE,action) {
      switch (action.type){
        case "SUCCESSFULLY_GET_CUSTOMER_DATA":
            return {...state,customersData:action.payload}
        default :
          return {...state}
      }
}
