import React from 'react';
import { TextField, Typography, Autocomplete } from '@mui/material';
import MDBox from 'components/MDBox';
import './css/index.scss';
import MDInput from 'components/MDInput';

const players = [
    'Kaltura', 'Native'
  ];

const paymentGateway = [
    'Amazon', 'Stripe', 'Custom'
  ];
function ThirdPartyConfig(props) {

  const handlePlayer = (event,value)=>{
    props.setUserDetails({...props.userDetails,player:value})
 }

 const handlePaymentGateway= (event,value)=>{
    props.setUserDetails({...props.userDetails,paymentGateway:{
        name:value=="Custom"?undefined:value,
        type:value=="Custom"?value:undefined,
    }})
 }

 const handlePaymentGatewayName= (event)=>{
    props.setUserDetails({...props.userDetails,paymentGateway:{
        ...props.userDetails.paymentGateway,
        name:event.target.value
    }})
 }

 const handlePaymentGatewayValue= (event)=>{
    props.setUserDetails({...props.userDetails,paymentGateway:{
        ...props.userDetails.paymentGateway,
        value:event.target.value
    }})
 }

  return (
    <MDBox>
      <div className='create_user'>
        <Typography>Thirdparty Config</Typography>

       
<div style={{gap:"6px",display:"flex" , flexDirection:"column"}}>
<Autocomplete 
          disablePortal
          size='small'
          id='combo-box-demo'
          options={players.map((feature) => {
            return feature;
          })}
          renderInput={(params) => <TextField {...params} label='Player' 
          error={props.userDetails.player ===""}
          helperText={props.userDetails.player === null || props.userDetails.player === undefined ? props.errorMessage : ""}
          />}
          sx={{paddingBottom:"10px",maxWidth:"50%",paddingTop:"10px"}}
          onChange={handlePlayer}
          value={props.userDetails.player ? props.userDetails.player : null}
          placeholder="Select Player"
        />

<Autocomplete 
          disablePortal
          size='small'
          id='combo-box-demo'
          options={paymentGateway.map((feature) => {
            return feature;
          })}
          renderInput={(params) => <TextField {...params} label='Payment Gateway' 
          error={props.userDetails.paymentGateway ===""}
          helperText={props.userDetails.paymentGateway === null || props.userDetails.paymentGateway === undefined ? props.errorMessage : ""}
          />}
          sx={{maxWidth:"50%"}}
          onChange={handlePaymentGateway}
          value={(props.userDetails?.paymentGateway?.type || props.userDetails?.paymentGateway?.name) ? ( props.userDetails?.paymentGateway?.type || props.userDetails?.paymentGateway?.name) : null}
          placeholder="Select Payment Gateway"
        />

        {
            props.userDetails?.paymentGateway?.type === "Custom"
            && <>
            <MDInput
          required
          id='Payment Gateway Name'
          size='small'
          label='Payment Gateway Name'
          name='Payment Gateway Name'
          sx={{maxWidth:"50%"}}
          onChange={handlePaymentGatewayName}
          value={(props.userDetails?.paymentGateway?.name ) ? (props.userDetails?.paymentGateway?.name ) : null}
          placeholder="only alphabetic charcters are allowed"
          error={props.userDetails.billingContactAddress ===""}
          helperText={props.userDetails.billingContactAddress === "" || props.userDetails.billingContactAddress === undefined ? props.errorMessage : ""}
        />
              <MDInput
          required
          id='Payment Gateway Value'
          size='small'
          label='Payment Gateway Value'
          name='Payment Gateway Value'
          sx={{maxWidth:"50%"}}
          onChange={handlePaymentGatewayValue}
          value={(props.userDetails?.paymentGateway?.value ) ? (props.userDetails?.paymentGateway?.value ) : null}
          placeholder="only alphabetic charcters are allowed"
          error={props.userDetails?.paymentGateway?.value ===""}
          helperText={props.userDetails?.paymentGateway?.value === "" || props.userDetails?.paymentGateway?.value === undefined ? props.errorMessage : ""}
        /></>
        }

</div>
    
      </div>
    </MDBox>
  );
}

export default ThirdPartyConfig;
