import { takeEvery, call, put } from '@redux-saga/core/effects'
import axios from 'axios';
import { disableCustomerApi } from 'consents/apis';
import { disableCustomerData } from 'Actions/disableCustomerAction';
import { X_API_KEY } from 'config';


export function* watchDisableCustomer(){
    yield takeEvery('DISABLE_CUSTOMER_ACTION',handleDisableCustomer);
}

function* handleDisableCustomer({payload}){
    const message = yield call(disableCustomer,payload);
    yield put(disableCustomerData(message))
}

const disableCustomer = async (customerId) =>{
    const customersID = {customerId:customerId}
    try{
        const res = await axios.put(disableCustomerApi,customersID,{
            headers:{"x-api-key":X_API_KEY}
        });
        return res.data
    }
    catch(err){
        return err.response.data
    }
}