import { combineReducers } from 'redux';
import CreateUserDetailsReducer from './createUserReducer';
import getCustomersDataReducer from './getCustomerDataReducer';
import disableCustomerReducer from './disableCustomerReducer';
import createDatabaseReducer from './createDatabaseReducer';
import getCustomerInfoReducer from './getCustomerInfoReducer';
import updateCustomerReducer from './updateCustomerReducer';
import createConfigReducer from './createConfigReducer';
import getConfigReducer from './getConfigReducer';
import updateConfigReducer from './updateConfigReducer';
import maintenanceSchedulesReducer from './maintenanceScheduleReducer';


export default combineReducers({
    CreateUserDetailsReducer,
    getCustomersDataReducer,
    disableCustomerReducer,
    createDatabaseReducer,
    getCustomerInfoReducer,
    updateCustomerReducer,
    createConfigReducer,
    getConfigReducer,
    updateConfigReducer, 
    maintenanceSchedulesReducer
});