import { Autocomplete, Card, Dialog, DialogActions, DialogContent, Grid, TextField} from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MDButton from "components/MDButton";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";

import { getMaintenanceScheduleAction, updateMaintenanceScheduleAction } from "Actions/maintenanceSchedule";
import { createMaintenanceScheduleAction } from "Actions/maintenanceSchedule";
import DateTimeComponent from "./components/DateTimePicker";

import { useNavigate } from "react-router-dom";

const maintenanceTypes = [
  'patch', 'minor', 'major'
];

function MaintenanceScheduleList(props){

  const [open,setOpen] = useState(false);
  const [addModel,setAddModel] = useState(false);
  const [updateData,setUpdateData]=useState({});
  const [errorMessage,setErrorMessage] = useState("")

    useEffect(()=>{
      props.getMaintenanceScheduleAction();
   },[props.customerListReset,props.updateDataReset])


    const handleMaintenanceName=(event)=>{
        setUpdateData({...updateData,name:event.target.value})
    }

    const handleMaintenanceType = (event,value)=>{
      if(value != updateData.type){
        setUpdateData({...updateData,type:value})
      }
    }


    const handleDescription = (event)=>{
      setUpdateData({...updateData,description:event.target.value})
    }


    const closeModal = () => {
      setOpen(false);
    };

    const closeAddModal = () => {
      setAddModel(false);
      setUpdateData({})
    };

    const handleupdate=(x)=>{
        setOpen(true)
        setUpdateData(x);
    }
    const handleAdd=()=>{
      setAddModel(true)
  }

  const handleStartDate = (value)=>{
    setUpdateData({...updateData,from:value})
  }

  const handleEndDate = (value)=>{
    setUpdateData({...updateData,till:value})
  }

    const handleupdateMaintenance= (context)=>{
     
      if(!updateData?._id ){
        console.log("ERROR NO ID")
      }
      if(updateData.name == "" || updateData.name == undefined ){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.from === "" | updateData.from == undefined ){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.till === "" | updateData.till == undefined ){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.type === "" | updateData.type == undefined ){
        setErrorMessage('mandatory field *')
      }
      else{
      
        if(context === "create"){
          props.createMaintenanceScheduleAction({body:{
            type:updateData.type,
            from:updateData.from,
            till:updateData.till,
            description:updateData.description,
            name:updateData.name
          }})
          closeAddModal()
        }else{
          props.updateMaintenanceScheduleAction({body:{
            _id:updateData._id,
            type:updateData.type,
            from:updateData.from,
            till:updateData.till,
            description:updateData.description,
            name:updateData.name
          }, params:{id:updateData?._id} });
        }
        
        setOpen(false);
      }
    }
    const getScheduledTimePeriod = (from, till) => {
      const startDate = new Date(from);
      const endDate = new Date(till);
      const currentDate = new Date();
    
      const startDay = startDate.getDate();
      const starttime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(startDate);
      const endtime = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(endDate);
      
      const endDay = endDate.getDate();
      const monthName = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(startDate);
      const year = startDate.getUTCFullYear();
    
      let scheduleString;
      
      if (startDay === endDay) {
        scheduleString = `${monthName} ${startDay}, ${year} From ${starttime} to ${endtime}`;
      } else {
        scheduleString = `${monthName} ${startDay}, ${year} ${starttime} to ${endDate.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}`;
      }
    
      let status;
      if (currentDate > endDate) {
        status = 'expired';
      } else if (currentDate < startDate) {
        status = 'scheduled';
      } else {
        status = 'live';
      }
    
      return {
        schedule: scheduleString,
        status: status
      };
    };

    const columns = [
      {Header:'SNO',accessor:'SNO'},
      {Header:'Name',accessor:'Name'},
      {Header:'Type',accessor:'Type'},
      {Header:'Schedule',accessor:'Schedule'},
      {Header:'Status',accessor:'Status'},
      {Header:'Edit',accessor:'Edit',align:'center'},
    ];

     let rowData = props?.maintenanceData?.maintenanceSchedules?.map((x,i)=>{
      const {schedule, status} = getScheduledTimePeriod(x.from,x.till)

      return {
       ...x,schedule,status 
      }

     })?.sort((a,b)=>{

      if(a.status == "live"){
        return -1
      } 
      else if (b.status == "live"){
        return 1
      } 
      else if(a.status == "scheduled"){
        return -1
      } 
      else if (b.status == "scheduled"){
        return 1
      }     if(a.status == "expired"){
        return -1
      } 
      else if (b.status == "expired"){
        return 1
      } 
     })

     let Rows = rowData?.map((x,i)=>{
      let {status, schedule} = x
      return {
        SNO:<MDTypography  variant="caption" color="dark" fontWeight="medium">{i+1}</MDTypography>,
        Name:<MDTypography  variant="caption" color="dark" fontWeight="medium" >{x.name.charAt(0).toUpperCase() + x.name.slice(1)}</MDTypography>,
        Type:<MDTypography  variant="caption" color="dark" fontWeight="medium">{x.type.charAt(0).toUpperCase() + x.type.slice(1)}</MDTypography>,
        Schedule:<MDTypography  variant="caption" color="dark" fontWeight="medium">{ schedule}</MDTypography>,
        Status:<MDTypography  variant="caption"  color="dark" sx={{color:status=="live"?"green":status=="expired"?"red":"black"}} fontWeight="medium">{status.charAt(0).toUpperCase() + status.slice(1)}</MDTypography>,
        Edit:<MDButton onClick={e=>handleupdate(x)}>
          <EditTwoToneIcon/>
        </MDButton>,
      }
     })

    return(
        <DashboardLayout>
            <DashboardNavbar />
              <MDBox pt={6} pb={3}>
                <Grid container spacing={3}>
                   <Grid item xs={12} md={12} lg={12} sm={12}>
                <Card >
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} // Flex styles
                >
                  <MDTypography variant="h6" color="white">
                    Maintenance Schedules
                  </MDTypography>
                  {
                    (props?.maintenanceData?.monthy_quota_remaining || 0 ) > 0 && <MDButton
                    color="white"
                    sx={{ mr: 1, color: 'blue' }}
                    onClick={handleAdd}
                  >
                    ADD
               </MDButton>
                  }
                  
</MDBox>

              <MDBox pt={3}>
                {<DataTable
                  table={{ columns: columns, rows: Rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />}
              </MDBox>
            </Card>
            </Grid> 
          </Grid> 
              </MDBox>  
            
              <MDBox>
                <Dialog
                    open={open} onClose={closeModal} scroll='body'>
                  <MDBox pt={6} pb={3} px={2} >
                <Grid container spacing={2}>
                   <Grid item xs={12} md={12} lg={12} sm={12}>
                <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                RE-Schedule Maintenance 
                </MDTypography>
              </MDBox>
                    <DialogContent>
                    <div style={{display:"flex", flexDirection:"row",paddingTop:'20px'}}>
                      <div style={{paddingRight:"20px" , width:"50%"}}>
                        <MDInput
                        onChange={handleMaintenanceName}
                        name='name'
                        id='nane'
                        label="Maintenance Name" 
                        value={updateData.name}
                        sx={{paddingBottom:"20px"}}
                        fullWidth
                        required
                        size='small'
                        helperText={updateData.name === "" ? errorMessage : ""}

                        />
                        <Autocomplete 
                          disablePortal
                          size='small'
                          required
                          id='combo-box-demo'
                          options={maintenanceTypes.map((type) => {
                             return type;
                          })}
                          renderInput={(params) => <TextField {...params} label='Maintenance Type'
                          helperText={updateData.maintenanceType === null ? errorMessage : ""} />}
                          onChange={handleMaintenanceType}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          value={updateData.type}
                        />
                     
                     <div style={{paddingBottom:"20px"}}>
                        <DateTimeComponent selectedDate={updateData.from && updateData.from.slice(0, 16)}  setSelectedDate={handleStartDate} label={"Start Date and Time"}/>
                        </div>
                     
                        <div style={{paddingBottom:"20px"}}>
                        <DateTimeComponent selectedDate={updateData.till && updateData.till.slice(0, 16)}  setSelectedDate={handleEndDate} label={"End Date and Time"}/>
                        </div>
                        <MDInput 
                          label='Description'
                          value={updateData.description}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          size='small'
                          onChange={handleDescription}
                          helperText={updateData.description === "" ? errorMessage : ""}
                        />
                        </div>
                        
                    </div>
                    </DialogContent>
                    <DialogActions>
                     <MDButton color='info' onClick={handleupdateMaintenance}>Update</MDButton>
                    <MDButton color='info' onClick={closeModal}>Cancel</MDButton>
                    </DialogActions>
                    </Card>
                    </Grid>
                    </Grid>
                  </MDBox>
                </Dialog> 
                <Dialog
                    open={addModel} onClose={closeAddModal} scroll='body'>
                  <MDBox pt={6} pb={3} px={2} >
                <Grid container spacing={2}>
                   <Grid item xs={12} md={12} lg={12} sm={12}>
                <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                 Schedule Maintenance 
                </MDTypography>
              </MDBox>
                    <DialogContent>
                    <div style={{display:"flex", flexDirection:"row",paddingTop:'20px'}}>
                      <div style={{paddingRight:"20px" , width:"50%"}}>
                        <MDInput
                        onChange={handleMaintenanceName}
                        name='name'
                        id='nane'
                        label="Maintenance Name" 
                        value={updateData.name}
                        sx={{paddingBottom:"20px"}}
                        fullWidth
                        required
                        size='small'
                        helperText={updateData.name === "" ? errorMessage : ""}

                        />
                        <Autocomplete 
                          disablePortal
                          size='small'
                          required
                          id='combo-box-demo'
                          options={maintenanceTypes.map((type) => {
                             return type;
                          })}
                          renderInput={(params) => <TextField {...params} label='Maintenance Type'
                          helperText={updateData.maintenanceType === null ? errorMessage : ""} />}
                          onChange={handleMaintenanceType}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          value={updateData.type}
                        />
                          <div style={{paddingBottom:"20px"}}>
                        <DateTimeComponent selectedDate={updateData.from && updateData.from.slice(0, 16)}  setSelectedDate={handleStartDate} label={"Start Date and Time"}/>
                        </div>
                     
                        <div style={{paddingBottom:"20px"}}>
                        <DateTimeComponent selectedDate={updateData.till && updateData.till.slice(0, 16)}  setSelectedDate={handleEndDate} label={"End Date and Time"}/>
                        </div>
                        <MDInput 
                          label='Description'
                          value={updateData.description}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          size='small'
                          onChange={handleDescription}
                          helperText={updateData.description === "" ? errorMessage : ""}
                        />
                        </div>
                        
                    </div>
                    </DialogContent>
                    <DialogActions>
                     <MDButton color='info' onClick={()=>handleupdateMaintenance( "create")}>Create</MDButton>
                    <MDButton color='info' onClick={closeAddModal}>Cancel</MDButton>
                    </DialogActions>
                    </Card>
                    </Grid>
                    </Grid>
                  </MDBox>
                </Dialog> 
                </MDBox>
              
        </DashboardLayout>
    )
}

const mapStateToProps = (state)=>{
  console.log(state)
       return{
          customersData:state.getCustomersDataReducer.customersData,
          customerListReset:state.disableCustomerReducer.customerListReset,
          updateDataReset:state.updateCustomerReducer.updateDataReset,
          maintenanceData:state.maintenanceSchedulesReducer?.maintenanceScheduleData
       }
}

const mapDispatchToProps = (dispatch)=>{
   return {
      getMaintenanceScheduleAction:()=>{
        dispatch(getMaintenanceScheduleAction())
      },
      updateMaintenanceScheduleAction:(data)=>{
        dispatch(updateMaintenanceScheduleAction(data))
      },
      createMaintenanceScheduleAction:(data)=>{
        dispatch(createMaintenanceScheduleAction(data))
      }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MaintenanceScheduleList);





