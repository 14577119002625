import { takeEvery, call, put,} from '@redux-saga/core/effects'
import axios from 'axios';
import { createDatabaseApi } from 'consents/apis';
import { createUserApi } from 'consents/apis';
import { createDatabaseData } from 'Actions/createDatabaseAction';
import { X_API_KEY } from 'config';
import { USER_X_API_KEY } from 'config';

export function* watchCreateDatabase(){
    yield takeEvery('CREATE_DATABASE_ACTION',handleCreateDatabase);
}

function* handleCreateDatabase({payload}){
    const message = yield call(createNewDatabase,payload);
    yield put(createDatabaseData(message));
}

const createNewDatabase = async (customerDetails) =>{
    try{
        const res = await axios.post(createDatabaseApi,customerDetails,{
            headers:{"x-api-key":X_API_KEY}
        });
        const userDetails = {
            customerId:customerDetails.customerId,
            tenantId:res.data.tenantId
        }
        const user = await axios.post(createUserApi,userDetails,
            {
                headers:{"x-api-key":USER_X_API_KEY}
            })
        return user.data.message
    }
    catch(err){
        return err.response.data
    }
}