import { PowerInputSharp } from '@mui/icons-material';
import { Autocomplete, Box, Button, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import React, { useEffect, useState } from 'react'

import { countiresList } from 'data/countries';

import './css/index.scss';

const allCountriesData = [
  "IND","US","UAE"
];
const ProductType = [
  'Standard', 'Premium'
];
const BillingDuration = [
  'Monthly', 'Quarterly', 'Annually'
];

function BillingDetails(props){

  const [billingCountryCode,setbillingCountryCode] = useState("");

  useEffect(() => {
    props.setUserDetails({...props.userDetails,billingCountryCode:billingCountryCode || props.userDetails.billingCountryCode})
  }, [billingCountryCode])
  
    const handleProductType = (event,value)=>{
       props.setUserDetails({...props.userDetails,productType:value})
    }

    const handleCountries = (event,value) =>{
      countiresList.map((x)=>{
        if(x.name == value){
          setbillingCountryCode(x.dialCode)
          props.setUserDetails({...props.userDetails,billingCountryCode:x.dialCode})
        }
      })
      props.setUserDetails({...props.userDetails , billingContactCountry:value})
    }

    const handleBillingName = (event)=>{
      props.setUserDetails({...props.userDetails,billingContactName:event.target.value})
    }

    const handleBillingEmail = (event) =>{
      props.setUserDetails({...props.userDetails, billingContactEmail:event.target.value})
    }

    const handleBillingAddress = (event)=>{
      props.setUserDetails({...props.userDetails,billingContactAddress:event.target.value})
    }

    const handleBillingContactPincode = (event)=>{
      props.setUserDetails({...props.userDetails,billingPincode:event.target.value})
    }


    const handleBillingPhoneNumber = (event) =>{
      props.setUserDetails({...props.userDetails,billingContactPhone:event.target.value})
    }

    const handleBillingID = (event)=>{
      props.setUserDetails({...props.userDetails,billingID:event.target.value})
    }

    const handleBillingDuration = (event,value)=>{
      props.setUserDetails({...props.userDetails,billingDuration:value})
    }

    return (
      <MDBox>
        <div className='create_user'>
           <Typography>Billing Details</Typography>
           
           <Autocomplete 
          disablePortal
          size='small'
          id='combo-box-demo'
          options={ProductType.map((producttype) => {
            return producttype;
          })}
          renderInput={(params) => <TextField {...params} label='Product Type' 
          error={props.userDetails.productType ===""}
          helperText={props.userDetails.productType === null || props.userDetails.productType === undefined ? props.errorMessage : ""}
          />}
          sx={{paddingBottom:"10px",maxWidth:"50%",paddingTop:"10px"}}
          onChange={handleProductType}
          value={props.userDetails.productType ? props.userDetails.productType : null}
          placeholder="select product type"
        />

        <MDInput
          margin='normal'
          required
          id='Billing Contact Name'
          size='small'
          label='Billing Contact Name'
          name='Billing Contact Name'
          sx={{maxWidth:"50%"}}
          onChange={handleBillingName}
          value={props.userDetails.billingContactName ? props.userDetails.billingContactName :""}
          placeholder="only alphabetic charcters are allowed"
          error={props.userDetails.billingContactName ===""}
          helperText={props.userDetails.billingContactName === "" || props.userDetails.billingContactName === undefined ? props.errorMessage : ""}
        />

        <MDInput
          margin='normal'
          required
          id='Billing Contact Email'
          size='small'
          label='Billing Contact Email'
          name='Billing Contact Email'
          sx={{maxWidth:"50%",paddingBottom:'14px'}}
          onChange={handleBillingEmail}
          value={props.userDetails.billingContactEmail ? props.userDetails.billingContactEmail:""}
          placeholder="ex:test@gmail.com"
          error={props.userDetails.billingContactEmail ===""}
          helperText={props.userDetails.billingContactEmail === "" || props.userDetails.billingContactEmail === undefined ? props.errorMessage : ""}
        />

        <Autocomplete 
          disablePortal
          size='small'
          id='combo-box-demo'
          options={countiresList.map((countires) => {
            return countires.name;
          })}
          renderInput={(params) => <TextField {...params} label='Select Country' 
          error={props.userDetails.billingContactCountry ===""}
          helperText={props.userDetails.billingContactCountry === null || props.userDetails.billingContactCountry === undefined ? props.errorMessage : ""}
          />}
          onChange={handleCountries}
          sx={{paddingBottom:"px",maxWidth:"50%"}}
          value={props.userDetails.billingContactCountry ? props.userDetails.billingContactCountry : null}
        />

        <MDInput
          margin='normal'
          required
          id='Billing Contact Name'
          size='small'
          label='Billing Address'
          name='Billing Address'
          sx={{maxWidth:"50%"}}
          onChange={handleBillingAddress}
          value={props.userDetails.billingContactAddress ? props.userDetails.billingContactAddress :""}
          placeholder="only alphabetic charcters are allowed"
          error={props.userDetails.billingContactAddress ===""}
          helperText={props.userDetails.billingContactAddress === "" || props.userDetails.billingContactAddress === undefined ? props.errorMessage : ""}
        />
        <MDInput
          margin='normal'
          required
          id='Billing Address Pincode'
          size='small'
          label='Billing Address Pincode'
          name='Billing Contact Name'
          sx={{maxWidth:"50%"}}
          onChange={handleBillingContactPincode}
          value={props.userDetails.billingPincode ? props.userDetails.billingPincode :""}
          placeholder="only alphabetic charcters are allowed"
          error={props.userDetails.billingPincode ===""}
          helperText={props.userDetails.billingPincode === "" || props.userDetails.billingPincode === undefined ? props.errorMessage : ""}
        />
        <MDInput
          margin='normal'
          required
          id='Billing Contact PhoneNumber'
          size='small'
          label='Billing Contact PhoneNumber'
          name='Billing Contact PhoneNumber'
          sx={{paddingBottom:"14px",maxWidth:"50%"}}
          onChange={handleBillingPhoneNumber}
          value={props.userDetails.billingContactPhone ? props.userDetails.billingContactPhone:""}
          placeholder="+919876543210"
          InputProps = {{
          startAdornment:<InputAdornment position="start">
             <Typography style={{fontSize:'14px'}}>{props.userDetails.billingCountryCode}</Typography>
            </InputAdornment>
          }}
          error={props.userDetails.billingContactPhone ===""}
          helperText={props.userDetails.billingContactPhone === "" || props.userDetails.billingContactPhone === undefined ? props.errorMessage : ""}
        />

       {/* <TextField
          margin='normal'
          required
          id='Billing ID'
          size='small'
          label='Billing ID'
          name='Billing ID'
          sx={{,maxWidth:"50%"}}
          onChange={handleBillingID}
          value={props.userDetails.billingID ? props.userDetails.billingID:""}
        />*/}
        
        <Autocomplete 
          disablePortal
          size='small'
          id='combo-box-demo'
          options={BillingDuration.map((billing) => {
            return billing;
          })}
          renderInput={(params) => <TextField {...params} label='Billing Duration' 
          error={props.userDetails.billingDuration ===""}
          helperText={props.userDetails.billingDuration === null || props.userDetails.billingDuration === undefined ? props.errorMessage : ""}
          />}
          sx={{maxWidth:"50%"}}
          onChange={handleBillingDuration}
          value={props.userDetails.billingDuration ? props.userDetails.billingDuration : null}
          placeholder="select billing duration"
        />
        </div>
        </MDBox>
    )
}

export default BillingDetails;