import { Autocomplete, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import Footer from "examples/Footer";
import { getCustomersListAction } from "Actions/getCustomerDataAction";
import { getCustomersListData } from "Actions/getCustomerDataAction";
import { connect } from "react-redux";
import UserTable from "./Table/userTable";
import MDButton from "components/MDButton";
import EditIcon from '@mui/icons-material/Edit';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PrintIcon from '@mui/icons-material/Print';
import { disableCustomerAction } from 'Actions/disableCustomerAction';
import { Box } from "@mui/system";
import MDInput from "components/MDInput";
import { countiresList } from 'data/countries';
import { updateCustomerAction } from "Actions/updateCustomerAction";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import UsersConfigs from "Pages/userConfig";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import './css/index.scss'
import { SignalCellularNullOutlined } from "@mui/icons-material";

const BillingDuration = [
  'Monthly', 'Quarterly', 'Annually'
];

const ProductType = [
  'Standard', 'Premium'
];


function UsersList(props){

  const [open,setOpen] = useState(false);
  const [updateData,setUpdateData]=useState({});
  const [reset,setReset] = useState(false)
  const [countryCode,setCountryCode] = useState("")
  const [configDialog,setConfigDialog] = useState(false);
  const [configOpen,setConfigOpen]=useState(false);
  const [errorMessage,setErrorMessage] = useState("")
  const [billingCountryCode,setBillingCountryCode] = useState("")
  const [mailError,setMailError] = useState(true)

    useEffect(()=>{
       props.getCustomersListAction();
       setReset(false);
    },[props.customerListReset,props.updateDataReset])

    const handleDisable= (x) =>{
        props.disableCustomerAction(x._id);
        setReset(true);
    }

    useEffect(() => {
      console.log('bug1')
      setUpdateData({...updateData,countryCode:countryCode || updateData.countryCode, contactPhoneNumber:""})
    }, [countryCode])

    useEffect(() => {
      console.log('bug2')
      setUpdateData({...updateData,billingCountryCode:billingCountryCode || updateData.billingCountryCode, billingContactPhone:""})
    }, [billingCountryCode])

    const handleCustomerName=(event)=>{
        setUpdateData({...updateData,customerName:event.target.value})
    }

    const handleLegalEntityName = (event)=>{
      setUpdateData({...updateData,legalEntityName:event.target.value})
    }

    const handleLegalEntityAddress = (event)=>{
      setUpdateData({...updateData,legalEntityAddress:event.target.value})
    }

    const handleCountry = (event,value)=>{
      if(value != updateData.country){
        countiresList.map((x)=>{
          if(x.name == value){
            setCountryCode(x.dialCode)
            //setUpdateData({...updateData,countryCode:x.dialCode})
          }
        })
        setUpdateData({...updateData,country:value})
      }
    }

    const handlePincode = (event)=>{
      setUpdateData({...updateData,pincode:event.target.value})
    }

    const handleContactName = (event)=>{
      setUpdateData({...updateData,contactName:event.target.value})
    }

    const handleContactEmail = (event)=>{
      setUpdateData({...updateData,contactEmail:event.target.value})
    }

    const handleContactPhoneNumber = (event)=>{
      
      setUpdateData({...updateData,contactPhoneNumber:event.target.value})
    }
   
    const handleProductType = (event,value)=>{
      setUpdateData({...updateData,productType:value})
    }

    const handleBillingName = (event)=>{
      setUpdateData({...updateData,billingContactName:event.target.value})
    }

    const handleBillingEmail = (event)=>{
      setUpdateData({...updateData,billingContactEmail:event.target.value})
    }

    const handleBillingAddress = (event)=>{
      setUpdateData({...updateData,billingContactAddress:event.target.value})
    }

    const handleBillingCountry = (event,value)=>{
      if(value != updateData.billingContactCountry){
        countiresList.map((x)=>{
          if(x.name == value){
            setBillingCountryCode(x.dialCode)
            //setUpdateData({...updateData,billingCountryCode:x.dialCode})
          }
        })
        setUpdateData({...updateData,billingContactCountry:value})
      }
      
    }

    const handleBillingPincode = (event)=>{
      setUpdateData({...updateData,billingPincode:event.target.value})
    }

    const handleBillingPhone = (event)=>{
      setUpdateData({...updateData,billingContactPhone:event.target.value})
    }

    const handleBillingDuration = (event,value)=>{
      setUpdateData({...updateData,billingDuration:value})
    }

    const closeModal = () => {
      setOpen(false);
    };

    const handleupdate=(x)=>{
        setOpen(true)
        setUpdateData(x);
    }

    const handleConfig = (x)=>{
      setConfigOpen(true);
      setConfigDialog(true);
      setUpdateData(x);
    }

    const configColse = ()=>{
        setConfigOpen(false)
    }

    const handleupdateCustomer = ()=>{
      console.log('comeing here')
      let mail = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);
      let mailValid = mail.test(updateData.contactEmail);
      console.log('mail pass',mailValid)
      if(mailValid === false){
          console.log('mail fail',mailValid)
          setMailError(false)
          //setErrorMessage("InvalidEmail address")
      }
      else{
        setMailError(true)
      }
      if(updateData.customerName === ""){
        console.log('coming 1st')
        setErrorMessage('mandatory field *')
      }
      else if(updateData.legalEntityName === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.legalEntityAddress === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.country === null){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.contactName === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.contactEmail === "" || mailValid === false){
        console.log('coming here')
        setErrorMessage('mandatory field * or enter valid email address')
      }
      else if(updateData.contactPhoneNumber === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.pincode === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.productType === null){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.billingContactName === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.billingContactEmail === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.billingContactAddress === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.billingContactCountry === null){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.billingContactPincode === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.billingContactPhone === ""){
        setErrorMessage('mandatory field *')
      }
      else if(updateData.billingDuration === null){
        setErrorMessage('mandatory field *')
      }
      else{
        //setReset(true);
        props.updateCustomerAction(updateData);
        setOpen(false);
      }
    }

    const columns = [
      {Header:'SNO',accessor:'SNO'},
      {Header:'Name',accessor:'Name'},
      {Header:'Email',accessor:'Email'},
      {Header:'Phone',accessor:'Phone'},
      {Header:'Status',accessor:'Status'},
      {Header:'Edit',accessor:'Edit',align:'center'},
      {Header:'Print',accessor:'Print',align:'center'},
      {Header:'Config',accessor:'Config',align:'center'},
      {Header:'Disable',accessor:'Disable',align:'center'},
    ];

     const Rows = props.customersData.map((x,i)=>{
       return {
           SNO:<MDTypography  variant="caption" color="dark" fontWeight="medium">{i+1}</MDTypography>,
           Name:<MDTypography  variant="caption" color="dark" fontWeight="medium" >{x.customerName}</MDTypography>,
           Email:<MDTypography  variant="caption" color="dark" fontWeight="medium">{x.contactEmail}</MDTypography>,
           Phone:<MDTypography  variant="caption" color="dark" fontWeight="medium">{x.contactPhoneNumber}</MDTypography>,
           Status:<MDTypography  variant="caption" color="dark" fontWeight="medium">{x.status}</MDTypography>,
           Edit:<MDButton onClick={handleupdate.bind(this,x)}>
             <EditTwoToneIcon/>
           </MDButton>,
           Print:<MDButton>
             <PrintIcon/>
           </MDButton>,
           Config:<MDButton onClick={handleConfig.bind(this,x)}>
             <SettingsApplicationsIcon/>
           </MDButton>,
           Disable:<MDButton onClick={handleDisable.bind(this,x)}>
             {x.status === "Enabled" ? <PersonIcon/>:<PersonOffIcon/>}
           </MDButton>
         }
     })

    return(
        <DashboardLayout>
            <DashboardNavbar />
              <MDBox pt={6} pb={3}>
                <Grid container spacing={3}>
                   <Grid item xs={12} md={12} lg={12} sm={12}>
                <Card >
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Customers 
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {<DataTable
                  table={{ columns: columns, rows: Rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />}
              </MDBox>
            </Card>
            </Grid> 
          </Grid> 
              </MDBox>  
              {updateData.status === "Enabled" ? 
              <MDBox>
                <Dialog
                    open={open} onClose={closeModal} scroll='body'>
                  <MDBox pt={6} pb={3} px={2} >
                <Grid container spacing={2}>
                   <Grid item xs={12} md={12} lg={12} sm={12}>
                <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                Update Customer 
                </MDTypography>
              </MDBox>
                    <DialogContent>
                    <div style={{display:"flex", flexDirection:"row",paddingTop:'20px'}}>
                      <div style={{paddingRight:"20px" , width:"50%"}}>
                        <MDInput
                        onChange={handleCustomerName}
                        name='Customer Name'
                        id='Customer Name'
                        label="customer Name" 
                        value={updateData.customerName}
                        sx={{paddingBottom:"20px"}}
                        fullWidth
                        required
                        size='small'
                        //error={updateData.customerName ===""}
                        helperText={updateData.customerName === "" ? errorMessage : ""}
                        />

                        <MDInput
                          label='legalEntityName'
                          value={updateData.legalEntityName}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleLegalEntityName}
                          helperText={updateData.legalEntityName === "" ? errorMessage : ""}
                        />
                        <MDInput
                          label='legalEntityAddress'
                          value={updateData.legalEntityAddress}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleLegalEntityAddress}
                          helperText={updateData.legalEntityAddress === "" ? errorMessage : ""}
                        />
                        <Autocomplete 
                          disablePortal
                          size='small'
                          required
                          id='combo-box-demo'
                          options={countiresList.map((countires) => {
                             return countires.name;
                          })}
                          renderInput={(params) => <TextField {...params} label='countries'
                          helperText={updateData.country === null ? errorMessage : ""} />}
                          onChange={handleCountry}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          value={updateData.country}
                        />
                        
                        <MDInput 
                          label='Contact Name'
                          value={updateData.contactName}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleContactName}
                          helperText={updateData.contactName === "" ? errorMessage : ""}
                        />
                        
                        <MDInput 
                          label='Contact Email'
                          value={updateData.contactEmail}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleContactEmail}
                          helperText={updateData.contactEmail === "" || mailError === false ? errorMessage : ""}
                        />
                        
                        <MDInput 
                          label='Contact Phonenumber'
                          value={updateData.contactPhoneNumber}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleContactPhoneNumber}
                          InputProps={{
                              startAdornment: <InputAdornment  position="start">
                                <Typography style={{fontSize:'14px'}}>{updateData.countryCode}</Typography>
                              </InputAdornment>,
                          }}
                          helperText={updateData.contactPhoneNumber === "" ? errorMessage : ""}
                        />

                        <MDInput 
                          label='pincode'
                          value={updateData.pincode}
                          sx={{paddingBottom:"20px",}}
                          fullWidth
                          required
                          size='small'
                          onChange={handlePincode}
                          helperText={updateData.pincode === "" ? errorMessage : ""}
                        />
                        </div>
                        <Divider orientation="vertical" />
                        <div style={{width:"50%"}}>
                        <Autocomplete 
                              disablePortal
                              size='small'
                              id='combo-box-demo'
                              options={ProductType.map((producttype) => {
                                  return producttype;
                              })}
                              renderInput={(params) => <TextField {...params} label='product Type'
                              helperText={updateData.productType === null ? errorMessage : ""} />}
                              sx={{paddingBottom:"22px"}}
                              fullWidth
                              required
                              onChange={handleProductType}
                              value={updateData.productType}
                              placeholder="select product type"
                        />
                        <MDInput
                          label='billing Contact Name'
                          value={updateData.billingContactName}
                          sx={{paddingBottom:"22px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleBillingName}
                          helperText={updateData.billingContactName === "" ? errorMessage : ""}
                        />
                        <MDInput
                          label='billing Contact Email'
                          value={updateData.billingContactEmail}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleBillingEmail}
                          helperText={updateData.billingContactEmail === "" ? errorMessage : ""}
                        />
                        <MDInput
                          label='billing Contact Address'
                          value={updateData.billingContactAddress}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleBillingAddress}
                          helperText={updateData.billingContactAddress === "" ? errorMessage : ""}
                        />
                        <Autocomplete 
                          disablePortal
                          size='small'
                          id='combo-box-demo'
                          options={countiresList.map((countires) => {
                             return countires.name;
                          })}
                          renderInput={(params) => <TextField {...params} label='select billing country' 
                          helperText={updateData.billingContactCountry === null ? errorMessage : ""}
                          />}
                          onChange={handleBillingCountry}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          value={updateData.billingContactCountry}
                         
                        />
                        <MDInput
                          label='billing Contact Pincode'
                          value={updateData.billingPincode}
                          sx={{paddingBottom:"20px",}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleBillingPincode}
                          helperText={updateData.billingPincode === "" ? errorMessage : ""}
                        />
                        <MDInput
                          label='billing contact Phonenumber'
                          value={updateData.billingContactPhone}
                          sx={{paddingBottom:"20px"}}
                          fullWidth
                          required
                          size='small'
                          onChange={handleBillingPhone}
                          InputProps={{
                              startAdornment: <InputAdornment  position="start">
                                <Typography style={{fontSize:'14px'}}>{updateData.billingCountryCode}</Typography>
                              </InputAdornment>,
                          }}
                          helperText={updateData.billingContactPhone === "" ? errorMessage : ""}
                        />
                        <Autocomplete 
                          disablePortal
                          required
                          size='small'
                          id='combo-box-demo'
                          options={BillingDuration.map((billing) => {
                             return billing;
                          })}
                          renderInput={(params) => <TextField {...params} label='billing Duration' 
                          helperText={updateData.billingDuration === null ? errorMessage : ""}
                          />}
                          sx={{ paddingBottom:'22px'}}
                          fullWidth
                          onChange={handleBillingDuration}
                          value={updateData.billingDuration}
                          placeholder="select billing duration"
                          
                        />
                      </div>
                    </div>
                    </DialogContent>
                    <DialogActions>
                     <MDButton color='info' onClick={handleupdateCustomer}>Update</MDButton>
                    <MDButton color='info' onClick={closeModal}>Cancel</MDButton>
                    </DialogActions>
                    </Card>
                    </Grid>
                    </Grid>
                  </MDBox>
                  
                </Dialog> 
                
                </MDBox>
                : 
                <Dialog open={open} onClose={closeModal} width='50px' >
                  <DialogContent>
                    sorry,customer is disabled so you cannot update 
                  </DialogContent>
                  <DialogActions>
                    <MDButton onClick={closeModal}>close</MDButton>
                  </DialogActions>
                </Dialog> }
                { configDialog ?
                  <Dialog open={configOpen} onClose={configColse} scroll='body'>
                     <UsersConfigs Data={updateData}
                                 configDialog={configDialog}
                                 configOpen={configOpen}
                                 setConfigDialog={setConfigDialog}
                                 setConfigOpen={setConfigOpen}
                                 setReset={setReset} />
                   </Dialog>:""}
        </DashboardLayout>
    )
}

const mapStateToProps = (state)=>{
       return{
          customersData:state.getCustomersDataReducer.customersData,
          customerListReset:state.disableCustomerReducer.customerListReset,
          updateDataReset:state.updateCustomerReducer.updateDataReset,
       }
}

const mapDispatchToProps = (dispatch)=>{
   return {
       getCustomersListAction:()=>{
           dispatch(getCustomersListAction())
       },
       disableCustomerAction:(customerId)=>{
        dispatch(disableCustomerAction(customerId))
      },
      updateCustomerAction:(customerDetails)=>{
        dispatch(updateCustomerAction(customerDetails))
      }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(UsersList);