import { takeEvery, call, put,} from '@redux-saga/core/effects'
import axios from 'axios';
import { createConfigApi } from 'consents/apis';
import { X_API_KEY } from 'config';

export function* watchCreateConfig (){
    yield takeEvery('CREATE_NEW_CONFIG',handleCreateConfig)
}

function* handleCreateConfig({payload}){
    const config = yield call(createNewConfig,payload)
}

const createNewConfig = async (configDetails)=>{
    try {
        const configData = await axios.post(createConfigApi,configDetails,{
            headers:{"x-api-key":X_API_KEY}
        })
    }
    catch(err){
        
    }
}