export const createDatabaseAction = (customerData)=>{
    return{type:"CREATE_DATABASE_ACTION",payload:customerData}
}

export const createDatabaseData = (message)=>{
    return{type:"CREATE_DATABSE_DATA",payload:message}
}

export const CreateDefaultUserData = (resData)=>{
    return{type:"CREATE_DEFAULT_USER_SUCCESSFULLY",payload:resData}
}