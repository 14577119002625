const INITAL_STATE = {
    message:{},
    defaultUserMessage:{}
}

export default function createDatabaseReducer(state = INITAL_STATE,action){
    switch(action.type){
        case "CREATE_DATABSE_DATA" :{
            return {...state,message:action.payload}
        }
        case "CREATE_DEFAULT_USER_SUCCESSFULLY":{
            return{...state,defaultUserMessage:action.payload}
        }
        default :{
            return{...state}
        }
    }

}