import { takeEvery, call, put } from '@redux-saga/core/effects';
import { updateMaintenanceScheduleResponseAction } from 'Actions/maintenanceSchedule';
import { createMaintenanceScheduleResponseAction } from 'Actions/maintenanceSchedule';
import { getMaintenanceScheduleResponseAction } from 'Actions/maintenanceSchedule';
import axios from 'axios';
import { X_API_KEY_DEV } from 'config';
import { maintenanceApi } from 'consents/apis';

export function* watchgetMaintenanceSchedule() {
    yield takeEvery('GET_MAINTENANCE_SCHEDULE', handleGetMaintenanceSchedule)
    yield takeEvery('UPDATE_MAINTENANCE_SCHEDULE', handleUpdateMaintenanceSchedule)
    yield takeEvery("CREATE_MAINTENANCE_SCHEDULE", handleCreateMaintenanceSchedule)
}

function* handleGetMaintenanceSchedule() {
    const res = yield call(getMaintenanceScheduleApi)
    let data = res?.data?.data
    console.log(data)
    if (data) {
        yield put(getMaintenanceScheduleResponseAction(data));
    }

}

function* handleUpdateMaintenanceSchedule(data) {
    const res = yield call(updateMaintenanceScheduleApi,data.payload)
    let _data = res?.data?.data
    if (_data) {
        yield put(updateMaintenanceScheduleResponseAction(_data));
    }
}

function* handleCreateMaintenanceSchedule(data) {
    const res = yield call(createMaintenanceScheduleApi,data.payload)
    let _data = res?.data?.data
    if (_data) {
        yield put(createMaintenanceScheduleResponseAction(_data));
    }
}

const getMaintenanceScheduleApi = async () => {
    try {
        const response = await axios.get(maintenanceApi, {
            headers: { "x-api-key":X_API_KEY_DEV }
        })
        console.log(response, "response")
        return response
    }
    catch (err) {
        console.log(err)
    }
}

const updateMaintenanceScheduleApi = async ({ body, params }) => {
   
    try {
        const response = await axios.put(maintenanceApi, body, {
            headers: { "x-api-key": X_API_KEY_DEV },
            params,
        },

        )
        return response
    }
    catch(err){
        console.log(err)
    }
}

const createMaintenanceScheduleApi = async ({ body, params }) => {
   
    try {
        const response = await axios.post(maintenanceApi, body, {
            headers: { "x-api-key": X_API_KEY_DEV }
        },

        )
        return response
    }
    catch(err){
        console.log(err)
    }
}