export const getConfigAction = (configPayload)=>{
    return{type:'GET_CONFIG_DETAILS',payload:configPayload}
}

export const getConfigDetails = (configData)=>{
    return{type:'SUCCESSFULLY_GET_CONFIG',payload:configData}
}

export const getConfigReset = ()=>{
    return{type:'CONFIG_RESET'}
}