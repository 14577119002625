
const INTIAL_STATE={
  maintenanceScheduleData:{
    maintenanceSchedules:[],
    monthy_quota_remaining:0,
  },
  updatedData:{},
  updateDataReset:false
   }

export default function maintenanceSchedulesReducer (state = INTIAL_STATE,action) {
      switch (action.type){
        case "SUCCESSFULLY_GET_MAINTENANCE_SCHEDULES":
          console.log(action,"action.payload")
            return {...state,maintenanceScheduleData:action.payload}
        case 'UPDATE_MAINTENANCE_SCHEDULE':
              return{...state,updatedData:action.payload,updateDataReset:!state.updateDataReset}
        case 'SUCCESSFULLY_UPDATE_MAINTENANCE_SCHEDULES':
              let updatedData = action.payload
              let updatedSchedules = state?.maintenanceScheduleData?.maintenanceSchedules?.map(ele=>{
                if (ele._id === updatedData._id){
                  return updatedData
                }
                return ele
              })
              return {...state,maintenanceScheduleData:{...state.maintenanceScheduleData, maintenanceSchedules:updatedSchedules}}
        case 'SUCCESSFULLY_CREATED_MAINTENANCE_SCHEDULE':
              let createdData = action.payload
              console.log(createdData,"createdData")
              let updateSchedules = [...state?.maintenanceScheduleData?.maintenanceSchedules,createdData]
              console.log(updateSchedules,"updateSchedules")
              console.log({...state,maintenanceScheduleData:{...state.maintenanceScheduleData, maintenanceSchedules:updateSchedules, monthy_quota_remaining:state.maintenanceScheduleData.monthy_quota_remaining-1}})
              return {...state,maintenanceScheduleData:{...state.maintenanceScheduleData, maintenanceSchedules:updateSchedules, monthy_quota_remaining:state.maintenanceScheduleData.monthy_quota_remaining-1}}
        default :
          return {...state}
      }
}
