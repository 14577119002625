import React, { useEffect, useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Typography, IconButton } from '@mui/material';
import { Box,  InputAdornment } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import MDBox from 'components/MDBox';
import './css/index.scss';
function FeatureConfig(props) {
  const [featureConfig, setFeatureConfig] = useState({
    sara: false,
    saraInsights: false,
    saraSearch: false,
    watched: 30,
    byw_watched: 30,
    watch_live: 5,
    sia:false,
    personalization:false,
    aiCompanion:false,
    gamification:false,
    kalturaRE:false
  });

  useEffect(()=>{
    setFeatureConfig(props.userDetails)
  },[])

  useEffect(() => {
    props.setUserDetails({
      ...props.userDetails,
      ...featureConfig,
    });
  }, [featureConfig]);

  const handleCheckboxChange = (event) => {
    setFeatureConfig({
      ...featureConfig,
      [event.target.name]: event.target.checked,
    });
  };

    const handleWatchedIncrement = () => {
    if ((featureConfig.watched+11) <= 100) {
      setFeatureConfig((prevConfig) => ({
        ...prevConfig,
        watched: (parseInt(prevConfig.watched) + 10)
      }));
    }
  };

  const handleWatchedDecrement = () => {
    if (featureConfig.watched-11 >= 0) {
      setFeatureConfig((prevConfig) => ({
        ...prevConfig,
        watched: (parseInt(prevConfig.watched) - 10)
      }));
    }
  };

  const handleWatchedInputChange = (event) => {
    let newValue;
    if(event.target.value){
      newValue = parseInt(event.target.value, 0);
    }else{
      newValue = 0
    }
  

    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      setFeatureConfig({
        ...featureConfig,
        watched: newValue,
      });
    }
  };

  const handleBYWIncrement = () => {
    if ((featureConfig.byw_watched+11) <= 100) {
      setFeatureConfig((prevConfig) => ({
        ...prevConfig,
        byw_watched: (parseInt(prevConfig.byw_watched) + 10)
      }));
    }
  };

  const handleBYWDecrement = () => {
    if (featureConfig.byw_watched-11 >= 0) {
      setFeatureConfig((prevConfig) => ({
        ...prevConfig,
        byw_watched: (parseInt(prevConfig.byw_watched) - 10)
      }));
    }
  };

  const handleBYWInputChange = (event) => {
    let newValue;
    if(event.target.value){
      newValue = parseInt(event.target.value, 0);
    }else{
      newValue = 0
    }
  

    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      setFeatureConfig({
        ...featureConfig,
        byw_watched: newValue,
      });
    }
  };

  const handleLiveEventIncrement = () => {
 
      setFeatureConfig((prevConfig) => ({
        ...prevConfig,
        watch_live: (parseInt(prevConfig.watch_live) + 5)
      }));
    
  };

  const handleLiveEventDecrement = () => {
    if (featureConfig.watch_live-6 >= 0) {
      setFeatureConfig((prevConfig) => ({
        ...prevConfig,
        watch_live: (parseInt(prevConfig.watch_live) - 5)
      }));
    }
  };

  const handleLiveEventInputChange = (event) => {
    let newValue;
    if(event.target.value){
      newValue = parseInt(event.target.value, 0);
    }else{
      newValue = 0
    }
  
    if (!isNaN(newValue) && newValue >= 0 ) {
      setFeatureConfig({
        ...featureConfig,
        watch_live: newValue,
      });
    }
  };
  return (
    <MDBox>
      <div className='create_user'>
        <Typography>Features</Typography>
     <div style={{marginLeft:"20px", display:"flex", gap:"10px"}}>
  <div>
  <FormControlLabel
        label="Sara"
  control={
    <Checkbox
      checked={featureConfig.sara}
      onChange={handleCheckboxChange}
      name="sara"
      color="primary"
    />
  }
  labelPlacement="end"
  style={{width:"fit-content"}}
/>
          <FormControlLabel
            control={
              <Checkbox
                checked={featureConfig.saraInsights}
                onChange={handleCheckboxChange}
                name="saraInsights"
                color="primary"
              />
            }
            label="Sara Insights"
            style={{width:"fit-content"}}
          />
  </div>

          <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={featureConfig.saraSearch}
                onChange={handleCheckboxChange}
                name="saraSearch"
                color="primary"
              />
            }
            label="Sara Search"
            style={{width:"fit-content"}}
          />

<FormControlLabel
            control={
              <Checkbox
                checked={featureConfig.sia}
                onChange={handleCheckboxChange}
                name="sia"
                color="primary"
              />
            }
            label="Sia"
            style={{width:"fit-content"}}
          />
          </div>
<div>
<FormControlLabel
            control={
              <Checkbox
                checked={featureConfig.aiCompanion}
                onChange={handleCheckboxChange}
                name="aiCompanion"
                color="primary"
              />
            }
            label="AI Companion"
            style={{width:"fit-content"}}
          />
          <FormControlLabel
          control={
            <Checkbox
              checked={featureConfig.gamification}
              onChange={handleCheckboxChange}
              name="gamification"
              color="primary"
            />
          }
          label="Gamification"
          style={{width:"fit-content"}}
        />
</div>
<div>
<FormControlLabel
          control={
            <Checkbox
              checked={featureConfig.personalization}
              onChange={handleCheckboxChange}
              name="personalization"
              color="primary"
            />
          }
          label="Personalization"
          style={{width:"fit-content"}}
        />
         <FormControlLabel
          control={
            <Checkbox
              checked={featureConfig.kalturaRE}
              onChange={handleCheckboxChange}
              name="kalturaRE"
              color="primary"
            />
          }
          label="Kalture Recomendation Engine"
          style={{width:"fit-content"}}
        />
</div>
        
     </div>

        <fieldset style={{ padding: '20px', borderRadius: '8px', border: '1px solid #ccc' }}>
          <legend>Application Control</legend>
<div style={{gap:"20px",display:"flex" , flexDirection:"column"}}>
          <MDBox display="flex" style={{gap:"160px"}} alignItems="center">
          <Typography style={{fontSize:"0.95rem"}}>Watch Event</Typography>
          <Box display="flex" alignItems="center" gap={"5px"}>
      <TextField
        type="number"
        
        value={featureConfig.watched || ""}
        onChange={handleWatchedInputChange}
        className='PercentageInput'
        InputProps={{
          inputProps: { min: 0, max: 100, step: 10 },
          endAdornment: (
            <InputAdornment style={{width:"fit-content"}} position="end">
              <Box display="flex"  flexDirection="column">
                <IconButton style={{
                    width:"13px",
                    height:"15px"
                }} size="small" onClick={(e)=>handleWatchedIncrement()}>
                  <KeyboardArrowUp />
                </IconButton>
                <IconButton style={{
                    width:"13px",
                    height:"15px"
                }} size="small" onClick={(e)=>handleWatchedDecrement()}>
                  <KeyboardArrowDown />
                </IconButton>
              </Box>
            </InputAdornment>
          ),
        }}
        name='watched'
        id='watched'
        style={{width:"70px"}}
      />

          <Typography style={{fontSize:"0.95rem"}}>Percentage</Typography>
    </Box>
          </MDBox>
          <MDBox display="flex" style={{gap:"50px"}} alignItems="center">
          <Typography style={{fontSize:"0.95rem"}}>Because You Watched Event</Typography>
          <Box display="flex" alignItems="center" gap={"5px"}>
      <TextField
        type="number"
        
        value={featureConfig.byw_watched || ""}
        onChange={handleBYWInputChange}
        className='PercentageInput'
        InputProps={{
          inputProps: { min: 0, max: 100, step: 10 },
          endAdornment: (
            <InputAdornment style={{width:"fit-content"}} position="end">
              <Box display="flex"  flexDirection="column">
                <IconButton style={{
                    width:"13px",
                    height:"15px"
                }} size="small" onClick={(e)=>handleBYWIncrement()}>
                  <KeyboardArrowUp />
                </IconButton>
                <IconButton style={{
                    width:"13px",
                    height:"15px"
                }} size="small" onClick={(e)=>handleBYWDecrement()}>
                  <KeyboardArrowDown />
                </IconButton>
              </Box>
            </InputAdornment>
          ),
        }}
        name='byw_watched'
        id='byw_watched'
        style={{width:"70px"}}
      />
          <Typography style={{fontSize:"0.95rem"}}>Percentage</Typography>
    </Box>
          </MDBox>
          <MDBox display="flex" style={{gap:"175px"}} alignItems="center">
          <Typography style={{fontSize:"0.95rem"}}>Live Event</Typography>
          <Box display="flex" alignItems="center" gap={"5px"}>
      <TextField
        type="number"
        
        value={featureConfig.watch_live || ""}
        onChange={handleLiveEventInputChange}
        className='PercentageInput'
        InputProps={{
          inputProps: { min: 0, max: 100, step: 10 },
          endAdornment: (
            <InputAdornment style={{width:"fit-content"}} position="end">
              <Box display="flex"  flexDirection="column">
                <IconButton style={{
                    width:"13px",
                    height:"15px"
                }} size="small" onClick={handleLiveEventIncrement}>
                  <KeyboardArrowUp />
                </IconButton>
                <IconButton style={{
                    width:"13px",
                    height:"15px"
                }} size="small" onClick={handleLiveEventDecrement}>
                  <KeyboardArrowDown />
                </IconButton>
              </Box>
            </InputAdornment>
          ),
        }}
        name='watch_live'
        id='watch_live'
        style={{width:"70px"}}
      />
       <Typography style={{fontSize:"0.95rem"}}>Minutes</Typography>
    </Box>
          </MDBox>
</div>
        </fieldset>
      </div>
    </MDBox>
  );
}

export default FeatureConfig;
