import { takeEvery, call, put,} from '@redux-saga/core/effects'
import axios from 'axios';
import { getConfigApi } from 'consents/apis';
import { getConfigDetails } from 'Actions/getConfigDetails';
import { X_API_KEY } from 'config';

export function* watchgetConfigDetails(){
    yield takeEvery('GET_CONFIG_DETAILS',handleGetConfig)
}

function* handleGetConfig({payload}){
    const Config = yield call(callGetConfig,payload)
    yield put(getConfigDetails(Config))
}

const callGetConfig = async (data)=>{
    const value = {t_id:data}
    try{
        const res = await axios.get(getConfigApi,{params:value,
            headers:{"x-api-key":X_API_KEY}
        })
        return res.data.data;
    }
    catch(err){
          return err.response.data;
    }
}