import { Card, CardContent, CardHeader, FormLabel, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import MDBox from 'components/MDBox'
import React, { useEffect, useState } from 'react'
import {Table,TableContainer,TableBody,TableHead,TableRow,TableCell} from '@mui/material'
import MDTypography from 'components/MDTypography'
import { getCustomerInfoAction } from 'Actions/getCustomerInfoAction'
import { connect } from 'react-redux'
import DoneIcon from '@mui/icons-material/Done';

import './css/index.scss'
import { DoneAll } from '@mui/icons-material'
import { Box } from '@mui/system'

function UsersInformation(props){
    const [showUserInfo,setShowUserInfo] = useState(true)    


    useEffect(()=>{
      console.log('msg',props.defaultUserMessage.hzStatus);
      if(props?.defaultUserMessage?.hzStatus == '200'){
        props.getCustomerInfoAction(props.usersData)
      }
    },[props.defaultUserMessage.hzStatus])

    if(props?.defaultUserMessage?.hzStatus != '200'){
      return (
        <Box display="flex">
        <div className='create_user'>
         <Typography>Creating Customer ...  </Typography>
         <Typography>Creating Database ... </Typography>
         <Typography>Creating User... </Typography>
        </div>
        <div>
          <Typography style={{paddingTop:'10px'}}> {props?.usersData?.insertedId != "" || undefined ? <DoneIcon/> : "" }</Typography> 
          <Typography> {props?.message?.userCreateMessage?.hzStatus == '200'? <DoneIcon/> : ""}</Typography> 
          <Typography> {props?.defaultUserMessage?.hzStatus =='200' ? <DoneIcon/>:""}</Typography> 
        </div>
        </Box>
      )
    }
    else {
    return (
        <MDBox>
           <div className='create_user'>
             Customer creation successful.The Username and Password send in your mail.
           </div> 
            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                     <TableRow>
                         <TableCell>Customer Details</TableCell>
                         <TableCell></TableCell>
                     </TableRow>
                  </TableHead>
                       {props?.customerInfo?.map((x,i)=>{
                         return (
                           <TableBody key={i}>
                           <TableRow>
                             <TableCell>CustomerName</TableCell>
                             <TableCell>{x.customerName}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Contact Name</TableCell>
                             <TableCell>{x.contactName}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Contact Email</TableCell>
                             <TableCell>{x.contactEmail}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Contact PhoneNumber</TableCell>
                             <TableCell>{x.contactPhoneNumber}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Product Type</TableCell>
                             <TableCell>{x.productType}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Billing Contact Name</TableCell>
                             <TableCell>{x.billingContactName}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Billing Contact Email</TableCell>
                             <TableCell>{x.billingContactEmail}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Billing Contact PhoneNumber</TableCell>
                             <TableCell>{x.billingContactPhone}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Billing Duration</TableCell>
                             <TableCell>{x.billingDuration}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Country</TableCell>
                             <TableCell>{x.country}</TableCell>
                           </TableRow>
                           <TableRow>
                             <TableCell>Pincode</TableCell>
                             <TableCell>{x.pincode}</TableCell>
                           </TableRow>
                           </TableBody>
                         )
                       })}
                </Table>
              </TableContainer>
           </div>
        </MDBox>
    )
  }
}

const mapStateToProps=(state)=>{
    return {
         usersData:state.CreateUserDetailsReducer.usersData,
         message:state.createDatabaseReducer.message,
         defaultUserMessage:state.createDatabaseReducer.defaultUserMessage,
         customerInfo:state.getCustomerInfoReducer.customerInfo
    }
  }

  const mapDispatchToProps=(dispatch)=>{
    return {
      getCustomerInfoAction:(customerId)=>{
        dispatch(getCustomerInfoAction(customerId))
      }
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(UsersInformation);